import BigNumber from 'bignumber.js'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, Text } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import useToast from 'hooks/useToast'
import { createAuctionEvent } from 'utils/callHelpers'
import { customNftAddress } from 'connectors'

interface ListingModalProps {
  onDismiss: () => void
  dismiss: () => void
  editMode: boolean
  tokenId: string
  tokenName: string
  tokenContract: any
  marketContract: any
  tokenOwnerAddress: string
}

const CustomButton = styled(Button)`
  background-color: ${(props) =>
    props.variant === 'secondary' ? 'white' : 'rgb(29, 162, 231)'};
  color: ${(props) =>
    props.variant === 'secondary' ? 'rgb(29, 162, 231)' : 'white'};
  border: ${(props) =>
    props.variant === 'secondary' ? '1px solid rgb(29, 162, 231)' : 'none'};
`
const LittleButton = styled(CustomButton)`
  background-color: rgb(173, 57, 67);
  height: 30px;
  font-size: 12px;
`

const MIN_LISTING_PRICE = new BigNumber(Number(0.5) * 1e18)

const ListingModal: React.FC<ListingModalProps> = ({
  onDismiss,
  dismiss,
  editMode,
  tokenId,
  tokenName,
  tokenContract,
  tokenOwnerAddress,
  marketContract,
}) => {
  const [tokenApprovePending, setTokenApprovePending] = useState(false)
  const [tokenApproved, setTokenEnabled] = useState(false)
  const [reserveVal, setReserveVal] = useState('0')
  const [buyNowVal, setBuyNowVal] = useState('0')
  const [pendingTx, setPendingTx] = useState(false)
  const { toastSuccess, toastError } = useToast()

  // check for approval
  useEffect(() => {
    const loadData = async () => {
      try {
        const marketAllowance = await tokenContract.callStatic.getApproved(
          tokenId
        )
        setTokenEnabled(marketAllowance === marketContract.address)
      } catch (e) {
        console.error('failed at setLpTokenBalance', e)
      }
    }
    loadData()
  }, [tokenContract, marketContract, tokenId])

  const handleBuyNowChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setBuyNowVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setBuyNowVal]
  )

  const handleReserveChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setReserveVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setReserveVal]
  )

  const onListToken = async (reservePrice, buyNowPrice) => {
    const duration = 60 * 60 * 24 * 3 // default 3 days
    const currency = '0x0000000000000000000000000000000000000000'
    try {
      if (editMode) {
        const auctionId = await marketContract.callStatic.auctionIdOfToken(
          tokenContract.address,
          tokenId
        )
        const tx = await marketContract.setAuctionReservePrice(
          auctionId,
          reservePrice.toString(),
          buyNowPrice.toString()
        )
        const result = await tx.wait(1)
        if (customNftAddress === tokenContract.address) {
          const res = await createAuctionEvent({
            auctionId: auctionId.toString(),
            tokenId,
            tokenAddress: tokenContract.address,
            tokenOwnerAddress: result.to,
            auctionCurrency: currency,
            duration: duration.toString(),
            reservePrice: reservePrice.toString(),
            buyNowPrice: buyNowPrice.toString(),
            status: 'APPROVED',
          })
          if (res.ok) {
            toastSuccess('Successfully edited listing.')
          } else {
            throw new Error('Error creating auction listing')
          }
        }
      } else {
        const tx = await marketContract.createAuction(
          tokenId,
          tokenContract.address,
          duration,
          reservePrice.toString(),
          currency,
          buyNowPrice.toString(),
          { value: '100000000000000000' }
        )
        const result = await tx.wait(1)
        if (customNftAddress === tokenContract.address) {
          const auctionCreatedEvent = result?.events?.find(
            (event) => event.args?.length && event.event === 'AuctionCreated'
          )
          if (!auctionCreatedEvent) {
            throw new Error('Error creating auction listing')
          }
          const listingResponse = await createAuctionEvent({
            auctionId: auctionCreatedEvent.args.auctionId.toString(),
            tokenId,
            tokenAddress: tokenContract.address,
            tokenOwnerAddress,
            auctionCurrency: currency,
            duration: duration.toString(),
            reservePrice: reservePrice.toString(),
            buyNowPrice: buyNowPrice.toString(),
            status: 'LISTED',
          })
          if (!listingResponse.ok) {
            throw new Error('Error creating auction listing')
          }
          const approvalResponse = await createAuctionEvent({
            auctionId: auctionCreatedEvent.args.auctionId.toString(),
            tokenId,
            tokenAddress: tokenContract.address,
            tokenOwnerAddress: result.to,
            auctionCurrency: currency,
            duration: duration.toString(),
            reservePrice: reservePrice.toString(),
            buyNowPrice: buyNowPrice.toString(),
            status: 'APPROVED',
          })
          if (!approvalResponse.ok) {
            throw new Error('Error creating auction listing')
          }
          toastSuccess('Successfully listed for sale.')
        }
      }
    } catch (e) {
      console.error(e, reservePrice, buyNowPrice)
      toastError('Error', 'Please try again and confirm the transaction.')
    }
  }

  const onCancelAuction = async () => {
    try {
      const auctionId = await marketContract.callStatic.auctionIdOfToken(
        tokenContract.address,
        tokenId
      )
      const tx = await marketContract.cancelAuction(auctionId)
      await tx.wait(1)
      if (customNftAddress === tokenContract.address) {
        const currency = '0x0000000000000000000000000000000000000000'
        const res = await createAuctionEvent({
          auctionId: auctionId.toString(),
          tokenId,
          tokenAddress: tokenContract.address,
          tokenOwnerAddress,
          auctionCurrency: currency,
          duration: '0',
          reservePrice: '0',
          buyNowPrice: '0',
          status: 'CANCELED',
        })
        if (res.ok) {
          toastSuccess('Successfully canceled auction')
        } else {
          throw new Error('Error canceling auction')
        }
      }
    } catch (e) {
      toastError('Error', String(e))
    }
  }

  const onEnable = async () => {
    setTokenApprovePending(true)
    try {
      const isEnabled = await tokenContract.approve(
        marketContract.address,
        tokenId
      )
      await isEnabled.wait(1)
      toastSuccess('Contract Enabled', 'You can now list this for sale!')
      setTokenEnabled(true)
    } catch (e) {
      toastError(
        'Error',
        'Please try again. Confirm the transaction and make sure you are paying enough gas!'
      )
      console.error('Enable error', e)
    }
    setTokenApprovePending(false)
  }

  const reserveValNumber = new BigNumber(Number(reserveVal) * 1e18)
  const buyNowValNumber = new BigNumber(Number(buyNowVal) * 1e18)
  return (
    <Modal title={tokenName} onDismiss={onDismiss}>
      {tokenApproved || editMode ? (
        <>
          <Text>
            Listings are auction style and last for 3 days.
            <br />
            You must set a starting price to list this NFT for sale.
            <br />
          </Text>
          <br />
          <br />
          <div style={{ display: 'flex' }}>
            <ModalInput
              value={reserveVal}
              allowZeroBalance
              onChange={handleReserveChange}
              symbol="BNB"
              inputTitle="Starting Bid Price"
              decimals={18}
            />
            <div style={{ width: '20px' }} />
            <ModalInput
              allowZeroBalance
              value={buyNowVal}
              onChange={handleBuyNowChange}
              symbol="BNB"
              inputTitle="Buy Now Price"
              decimals={18}
            />
          </div>
          <br />
          <Text fontSize="13px">
            * Listing fee of 0.01 BNB and 5% on sale applies
            <br />* If specifying a buy price, it must be higher than the
            reserve price.
            <br />* Min listing price is 0.5 BNB
          </Text>
          <ModalActions>
            <CustomButton
              variant="subtle"
              onClick={onDismiss}
              width="100%"
              disabled={pendingTx}
            >
              Close
            </CustomButton>
            <CustomButton
              width="100%"
              disabled={
                pendingTx ||
                !reserveValNumber.isFinite() ||
                reserveValNumber.eq(0) ||
                Number(reserveValNumber) < Number(MIN_LISTING_PRICE)
              }
              onClick={async () => {
                setPendingTx(true)
                await onListToken(reserveValNumber, buyNowValNumber)
                setPendingTx(false)
                onDismiss()
                dismiss()
              }}
            >
              {pendingTx ? 'Pending Confirmation' : 'Confirm'}
            </CustomButton>
          </ModalActions>
          {editMode && (
            <LittleButton
              variant="danger"
              onClick={async () => {
                setPendingTx(true)
                await onCancelAuction()
                setPendingTx(false)
                onDismiss()
                dismiss()
              }}
              width="100%"
              disabled={pendingTx}
            >
              Cancel Auction
            </LittleButton>
          )}
        </>
      ) : (
        <>
          <CustomButton
            onClick={onEnable}
            width="100%"
            disabled={tokenApprovePending}
          >
            {tokenApprovePending ? 'Pending...' : 'Enable Listing'}
          </CustomButton>
        </>
      )}
    </Modal>
  )
}

export default ListingModal
