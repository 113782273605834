import { ConnectorNames } from '@pancakeswap-libs/uikit'
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { customNftAddressByChain } from 'config'

import { BscConnector } from '@binance-chain/bsc-connector'
import Web3 from 'web3'

import { NetworkConnector } from './NetworkConnector'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? '56'
)

export const customNftAddress = customNftAddressByChain[NETWORK_CHAIN_ID]
export const storageFeeWalletAddress =
  process.env.REACT_APP_STORAGE_FEE_WALLET_ADDRESS

export const apiUrl = `${process.env.REACT_APP_GHC_API_HOST}${process.env.REACT_APP_GHC_API_URI}`

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`
  )
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary =
    networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [56, 97, 1337, 1],
})

export const bscConnector = new BscConnector({ supportedChainIds: [56] })

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  qrcode: true,
  chainId: 56,
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
}

const httpProvider = new Web3.providers.HttpProvider(NETWORK_URL)

export const web3NoAccount = new Web3(
  window.web3?.currentProvider || httpProvider
)
export const web3WriteAccount = new Web3(
  window.ethereum || window.web3?.currentProvider
)
