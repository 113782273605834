import React from 'react'
import styled from 'styled-components'
import {
  Text,
  Button,
  Input,
  InputProps,
  Flex,
  Link,
} from '@pancakeswap-libs/uikit'
import { BigNumber } from 'bignumber.js'

interface ModalInputProps {
  max?: string
  symbol: string
  onSelectMax?: () => void
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  value: string
  addLiquidityUrl?: string
  inputTitle?: string
  decimals?: number
  allowZeroBalance?: boolean
  minimum?: string
  style?: any
}

const getBoxShadow = ({ isWarning = false, theme }) => {
  if (isWarning) {
    return theme.shadows.warning
  }

  return theme.shadows.inset
}

const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  box-shadow: ${getBoxShadow};
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 16px 8px 0;
  width: 100%;
`

const StyledInput = styled(Input)`
  box-shadow: none;
  width: 60px;
  margin: 0 8px;
  padding: 0 8px;

  ${({ theme }) => theme.mediaQueries.xs} {
    width: 80px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 150px;
  }
`

const StyledErrorMessage = styled(Text)`
  position: absolute;
  bottom: -22px;
  a {
    display: inline;
  }
`

const CustomButton = styled(Button)`
  background-color: rgb(29, 162, 231);
`

const ModalInput: React.FC<ModalInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  addLiquidityUrl,
  inputTitle,
  decimals = 18,
  allowZeroBalance = false,
  minimum = 0,
  style={}
}) => {
  const isBalanceZero = (max === '0' || !max) && !allowZeroBalance

  const displayBalance = (balance: string) => {
    if (isBalanceZero) {
      return '0'
    }
    const balanceBigNumber = new BigNumber(balance)
    if (balanceBigNumber.gt(0) && balanceBigNumber.lt(0.0001)) {
      return balanceBigNumber.toLocaleString()
    }
    return balanceBigNumber.toFixed(3, BigNumber.ROUND_DOWN)
  }

  return (
    <div
      style={{
        position: 'relative',
        maxWidth: 350,
        alignSelf: 'center',
        width: '100%',
        ...style
      }}
    >
      <StyledTokenInput isWarning={isBalanceZero}>
        <Flex justifyContent="space-between" pl="16px">
          <Text fontSize="14px">{inputTitle}</Text>
          {max !== undefined && (
            <Text fontSize="14px">Balance: {displayBalance(max)}</Text>
          )}
        </Flex>
        <Flex alignItems="flex-end" justifyContent="space-around">
          <StyledInput
            pattern={`^[0-9]*[.,]?[0-9]{0,${decimals}}$`}
            inputMode="decimal"
            step="any"
            min={minimum || "0"}
            onChange={onChange}
            value={value}
          />
          {max !== undefined && (
            <CustomButton scale="sm" onClick={onSelectMax} mr="8px">
              Max
            </CustomButton>
          )}
          <Text fontSize="16px">{symbol}</Text>
        </Flex>
      </StyledTokenInput>
      {isBalanceZero && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {'No tokens to stake '}
          <Link
            fontSize="14px"
            bold={false}
            href={addLiquidityUrl}
            external
            color="failure"
          >
            Get {symbol}
          </Link>
        </StyledErrorMessage>
      )}
    </div>
  )
}

export default ModalInput
