import React from 'react'
import CardFlex from 'components/CardFlex'
import NftAttribute from 'components/NftAttribute'

const CollectionAttributesCard = ({ collectionAttributes, cardAttributes }) => {
  const totalRarity = cardAttributes.length
  let thisRarity = 0
  let ultraRareBoost = 1

  return (
    <>
      {cardAttributes.map((attr: any) => {
        const attrName = attr.trait_type.replace(/[^a-zA-Z ]/g, '')
        const value = attr.value.replace('_', ' ')
        const collectionTraitType = collectionAttributes[attr.trait_type] || {}
        const pct =
          (collectionTraitType[attr.value] || 0) /
          (collectionTraitType.total || 1)
        thisRarity += pct
        if (attrName === 'animated') {
          ultraRareBoost += 4
          if (value.includes('ultrarare')) {
            ultraRareBoost += 50
          }
        }
        return (
          <NftAttribute
            attribute={{ attrName, attrValue: value, attrPct: pct }}
          />
        )
      })}
      <CardFlex>
        <div>
          <b>Rarity Score:</b>
        </div>
        <div>
          <b>
            {totalRarity &&
              `(${((thisRarity / totalRarity / ultraRareBoost) * 100).toFixed(
                1
              )}%)`}
          </b>
        </div>
      </CardFlex>
      *Lower rarity % = more rare.
    </>
  )
}

export default CollectionAttributesCard
