import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text } from '@pancakeswap-libs/uikit'
import Input from 'components/Input'

const AttributeRow = styled.tr``

const AttributeCell = styled.td`
  padding: 8px 0;

  :nth-child(1) {
    padding-right: 8px;
  }
  :nth-child(2) {
    padding-left: 8px;
  }
`

const AttributeTable = ({
  keyHeader,
  valueHeader,
  attributesList,
  onAttributesListChanged,
  showWarnings,
}) => {
  const newAttributeKeyInput = useRef<HTMLInputElement>(null)
  const newAttributeValueInput = useRef<HTMLInputElement>(null)
  const [focusedInput, setFocusedInput] = useState('')

  const onAddNewAttribute = useCallback(
    (id, field) => {
      if (newAttributeKeyInput.current?.value) {
        setFocusedInput(`${id}:${field}`)
        onAttributesListChanged([
          ...attributesList,
          {
            id,
            key: newAttributeKeyInput.current.value,
            value: newAttributeValueInput.current?.value,
          },
        ])
      }
    },
    [attributesList, onAttributesListChanged]
  )

  const onUpdateAttributes = useCallback(
    (id, newKey, newValue) => {
      const arrayIndex = attributesList.findIndex((attr) => attr.id === id)
      const newAttributesList = [...attributesList]
      newAttributesList.splice(arrayIndex, 1, {
        id,
        key: newKey,
        value: newValue,
      })
      onAttributesListChanged(newAttributesList)
    },
    [attributesList, onAttributesListChanged]
  )

  const onFinishAttribute = useCallback(
    (id, key) => {
      if (!key) {
        const arrayIndex = attributesList.findIndex((attr) => attr.id === id)
        const newAttributesList = [...attributesList]
        newAttributesList.splice(arrayIndex, 1)
        onAttributesListChanged(newAttributesList)
      }
    },
    [attributesList, onAttributesListChanged]
  )

  useEffect(() => {
    newAttributeKeyInput.current?.focus()
  }, [newAttributeKeyInput])

  const newId = useMemo(
    () => (attributesList.slice(-1)[0]?.id ?? -1) + 1,
    [attributesList]
  )

  return (
    <table>
      <tbody>
        {attributesList?.map((attribute) => (
          <AttributeRow key={attribute.id}>
            <AttributeCell>
              <Input
                key={`${attribute.id}:key`}
                value={attribute.key}
                onChange={(evt) =>
                  onUpdateAttributes(
                    attribute.id,
                    evt.target.value,
                    attribute.value
                  )
                }
                ref={(input) => {
                  if (input && focusedInput === `${attribute.id}:key`) {
                    input.focus()
                    setFocusedInput('')
                  }
                }}
                onBlur={(evt) =>
                  onFinishAttribute(attribute.id, evt.target.value)
                }
              />
            </AttributeCell>
            <AttributeCell>
              <Input
                isWarning={showWarnings && attribute.value === ''}
                placeholder={
                  showWarnings && attribute.value === ''
                    ? 'required'
                    : undefined
                }
                key={`${attribute.id}:value`}
                value={attribute.value}
                onChange={(evt) =>
                  onUpdateAttributes(
                    attribute.id,
                    attribute.key,
                    evt.target.value
                  )
                }
                ref={(input) => {
                  if (input && focusedInput === `${attribute.id}:value`) {
                    input.focus()
                    setFocusedInput('')
                  }
                }}
              />
            </AttributeCell>
          </AttributeRow>
        ))}
        <AttributeRow key={newId}>
          <AttributeCell>
            <Input
              onChange={(evt) => onAddNewAttribute(newId, 'key')}
              ref={newAttributeKeyInput}
              placeholder={`Enter new ${keyHeader.toLowerCase()}`}
            />
          </AttributeCell>
          <AttributeCell>
            <Input
              onChange={(evt) => onAddNewAttribute(newId, 'value')}
              ref={newAttributeValueInput}
              placeholder={`Enter new ${valueHeader.toLowerCase()}`}
            />
          </AttributeCell>
        </AttributeRow>
      </tbody>
    </table>
  )
}

export default AttributeTable
