import styled from 'styled-components'
import { Input as PancakeInput } from '@pancakeswap-libs/uikit'

const Input = styled(PancakeInput)`
  width: 100%;
  border: none;
  border-radius: 8px;
  ${({ isWarning, theme }) =>
    isWarning &&
    `
  ::placeholder {
    color: ${theme.colors.failure}};
  `}
`

export default Input
