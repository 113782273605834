import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useActiveWeb3React } from 'hooks'
import BigNumber from 'bignumber.js'
import { useContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import styled from 'styled-components'
import { Card, CardBody, Button, Flex, Text } from '@pancakeswap-libs/uikit'
import {
  createAuctionEvent,
  getAuctionSummaryForNft,
  getInternalNFTDetails,
  updateNftStatus,
  validateNftStorageFeeTransaction,
} from 'utils/callHelpers'
import NftCollectionAttributes from 'components/NftCollectionAttributes'
import NftAttribute from 'components/NftAttribute'
import CountdownTimer from 'components/CountdownTimer'
import Container from 'components/Container'
import { shortenAccount } from 'utils/formatAccount'
import { useModal } from '@pancakeswap-libs/uikit'
import * as CustomNft from 'config/abis/CustomNFT.json'
import * as NftMarketplace from 'config/abis/NftMarketplace.json'
import { marketAddressByChain } from 'config'
import { getDecimalAmount, getNumber } from 'utils/formatBalance'
import { SubHeading, Heading, TextBody, TextHeading } from './components'
import ListingModal from './ListingModal'
import BiddingModal from './BiddingModal'
import { AppState } from '../../state'
import { setStatus } from '../../state/customNfts/mint'
import { storageFeeWalletAddress } from '../../connectors'

const MARKETPLACE_READY = true

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100vh;
  max-width: 100%;
  justify-content: top;
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(29, 162, 231);
  box-shadow: rgb(0 146 224) 0px 0px 1.5rem,
    rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  text-align: left;
`
const ImageCard = styled(CardWrapper)`
  width: 100%;
  max-width: 60vh;
  margin-top: 30px;
`

const FlexElement = styled.div`
  @media only screen and (max-width: 780px) {
    padding: 10px;
  }
  padding: 30px;
  flex: 1;
  justify-content: center;
`

export const Btn = styled(Button)`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: rgb(29, 162, 231);
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: rgba(178, 96, 220, 0.8);
  }
`

const MARKET_IS_READY = true

const executeTransaction = async (
  provider,
  amountBnb,
  successCallback,
  failureCallback
) => {
  try {
    const receipt = await provider.getSigner().sendTransaction({
      to: storageFeeWalletAddress,
      value: BigInt(amountBnb.shiftedBy(18).integerValue()),
    })
    successCallback(receipt)
  } catch (e) {
    console.error(e)
    failureCallback(e)
  }
}

export default function CustomContractDetails({
  history,
  nftCollectionAddress,
  databaseId,
}) {
  const { account, chainId, library } = useActiveWeb3React()
  const { bnbUsdt } = useSelector((state: AppState) => state.global.prices)
  const dispatch = useDispatch()
  const marketAddress = marketAddressByChain[chainId || '56']
  const nftContract = useContract(nftCollectionAddress, CustomNft.abi, true)
  const marketContract = useContract(marketAddress, NftMarketplace.abi, true)

  // TODO:
  // implement end auction

  const [loading, setLoading] = useState(true)
  const [collectionName, setCollectionName] = useState('')
  const [tokenName, setName] = useState('')
  const [nftOwner, setNftOwner] = useState('')
  const [desc, setDesc] = useState('')
  const [cardMediaType, setMediaType] = useState('')
  const [tokenUri, setTokenUri] = useState('')
  const [chainTokenId, setChainTokenId] = useState('0')
  const [cardMediaUri, setMediaUri] = useState('')
  const [cardAttributes, setAttributes] = useState([])
  const [retries, setRetries] = useState(0)
  const [collectionAttributes, setCollectionAttributes] = useState({})
  const [auctionId, setAuctionId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [renderKey, setRenderKey] = useState('0')
  const { toastSuccess, toastError } = useToast()

  // Auction Details
  const [aucHighestBid, setAucHighestBid] = useState(0)
  const [aucEndTime, setAucEndTime] = useState(0)
  const [aucFirstBidTime, setAucFirstBidTime] = useState(0)
  const [aucReservePrice, setAucReservePrice] = useState(0)
  const [aucHighestBidder, setAucHighestBidder] = useState('')
  const [aucCurrency, setAucCurrency] = useState('')
  const [aucBuyNowPrice, setAucBuyNowPrice] = useState(0)
  const [aucDuration, setAuctionDuration] = useState(259200) // default 3 days
  const [storageFee, setStorageFee] = useState(0)
  const [nftCreator, setNftCreator] = useState('')
  const [nftStatus, setNftStatus] = useState('created')
  const [mintStatus, setMintStatus] = useState('not-minting')
  const [auctionOwner, setAuctionOwner] = useState('')
  const [auctionStatus, setAuctionStatus] = useState('')

  const failedToCollectStorageFee = useCallback(
    async (error) => {
      dispatch(setStatus('failure'))
      setNftStatus('error')
      setMintStatus('error:storage-fee-declined')
      console.error(error)
      toastError(
        'Storage fee was not processed. NFT will not be minted. Please try again.'
      )
    },
    [dispatch, toastError]
  )

  const handleMint = useCallback(async () => {
    const tx = await nftContract?.mint(tokenUri, account, BigInt(1e22))
    const message = await tx.wait(1)

    const mintedEvent = message?.events?.find(
      (event) => event.event === 'Minted'
    )

    if (!mintedEvent?.args?.nftID) {
      throw Error('Mint failed')
    }
    return mintedEvent?.args?.nftID.toString()
  }, [tokenUri, account, nftContract])

  const sendMintRequest = useCallback(async () => {
    try {
      setMintStatus('pending::minting')
      const mintedTokenId = await handleMint()
      if (mintedTokenId) {
        await updateNftStatus(databaseId, mintedTokenId, 'MINTED')
        setChainTokenId(mintedTokenId)
        setNftStatus('minted')
        setMintStatus('complete::success')
        toastSuccess('Token minted successfully.')
      } else {
        setNftStatus('complete::error::mint-failure')
        toastError('Unexpected error. Please try again.')
      }
    } catch (e) {
      console.error('error', e)
      setMintStatus('complete::error::user-denied')
      // setAccountState('error')
      toastError(
        'Error',
        'Please try again. Confirm the transaction and make sure you are paying enough gas!'
      )
    }
  }, [handleMint, databaseId, toastError, toastSuccess])

  const onStorageFeePaid = useCallback(
    async (feeReceipt) => {
      setMintStatus('pending::validating-storage-fee')
      const storageFeeValidationResponse =
        await validateNftStorageFeeTransaction(databaseId, feeReceipt)
      if (storageFeeValidationResponse.ok) {
        setNftStatus('storage-fee-paid')
        sendMintRequest()
      } else if (storageFeeValidationResponse.status >= 500) {
        setMintStatus('complete::error::storage-fee-validation-failed')
        toastError(
          'There was an issue processing your request. Please try again later.'
        )
        setNftStatus('error')
      } else if (storageFeeValidationResponse.status === 422) {
        setMintStatus('complete::error::storage-fee-validation-failed')
        toastError('Storage fee not received. NFT will not be minted.')
        setNftStatus('error')
      } else {
        setMintStatus('complete::error::storage-fee-validation-failed')
        toastError('Unexpected error. Please try again.')
        setNftStatus('error')
      }
    },
    [databaseId, sendMintRequest, toastError]
  )

  const requestFileStoragePayment = useCallback(() => {
    setMintStatus('pending::requesting-storage-fee')
    const EXCHANGE_RATE_BNB_USD = chainId === 56 ? bnbUsdt : new BigNumber(400)
    const storageFeeBnb = storageFee
      ? new BigNumber(storageFee).dividedBy(EXCHANGE_RATE_BNB_USD)
      : new BigNumber(0)
    executeTransaction(
      library,
      storageFeeBnb,
      onStorageFeePaid,
      failedToCollectStorageFee
    )
  }, [
    chainId,
    bnbUsdt,
    library,
    storageFee,
    onStorageFeePaid,
    failedToCollectStorageFee,
  ])

  const minted = useMemo(
    () => nftStatus.toLowerCase() === 'minted',
    [nftStatus]
  )
  const mintInProgress = useMemo(
    () => mintStatus.toLowerCase().startsWith('pending::'),
    [mintStatus]
  )
  const isCreator = useMemo(
    () => nftCreator?.toLowerCase() === account?.toLowerCase(),
    [nftCreator, account]
  )
  const isOwner = useMemo(
    () => nftOwner?.toLowerCase() === account?.toLowerCase(),
    [nftOwner, account]
  )
  const isAuctionOwner = useMemo(
    () => auctionOwner?.toLowerCase() === account?.toLowerCase(),
    [auctionOwner, account]
  )
  const isTokenListed = useMemo(
    () =>
      auctionStatus === 'LISTED' ||
      auctionStatus === 'APPROVED' ||
      auctionStatus === 'OPEN',
    [auctionStatus]
  )
  const isForSale = useMemo(
    () => auctionStatus === 'APPROVED' || auctionStatus === 'OPEN',
    [auctionStatus]
  )
  const src =
    cardMediaUri || `${process.env.REACT_APP_PUBLIC_URL}/images/new_loading.gif`
  const floorPrice = Math.max(aucReservePrice, aucHighestBid)
  const auctionEnded = useMemo(
    () =>
      !isTokenListed || (aucFirstBidTime && aucEndTime < new Date().getTime()),
    [isTokenListed, aucFirstBidTime, aucEndTime]
  )

  useEffect(() => {
    const loadInternalAuctionData = async (theChainTokenId, tokenCreator) => {
      if (minted || isForSale) {
        try {
          const auctionResponse = await getAuctionSummaryForNft(theChainTokenId)
          if (auctionResponse.ok) {
            const {
              auctionId: aucId,
              tokenOwnerAddress,
              auctionOwnerAddress,
              auctionCurrency,
              reservePrice,
              buyNowPrice,
              highestBidderAddress,
              highestBid,
              firstBidTime,
              duration,
              endTime,
              status,
            } = await auctionResponse.json()

            setNftOwner(tokenOwnerAddress)
            setAuctionId(aucId)
            if (highestBid) {
              setAucHighestBid(getNumber(highestBid))
            }
            if (firstBidTime) {
              setAucFirstBidTime(Number(new Date(firstBidTime).getTime()))
              setAucEndTime(new Date(endTime).getTime())
            }
            setAucReservePrice(getNumber(reservePrice))
            setNftOwner(tokenOwnerAddress)
            setAuctionOwner(auctionOwnerAddress)
            setAucHighestBidder(highestBidderAddress)
            setAucCurrency(auctionCurrency)
            setAucBuyNowPrice(getNumber(buyNowPrice))
            setAuctionDuration(Number(duration))
            setAuctionStatus(status)
          } else if (auctionResponse.status === 404) {
            console.warn('Auction not found', auctionResponse)
            // TODO: WHAT ABOUT THE SECOND AUCTION
            setNftOwner(tokenCreator)
            // TODO: WHAT ABOUT THE SECOND AUCTION
            setAuctionId('')
            setAucHighestBid(0)
            setAucFirstBidTime(0)
            setAucEndTime(0)
            setAucReservePrice(0)
            setAuctionOwner('')
            setAucHighestBidder('')
            setAucCurrency('')
            setAucBuyNowPrice(0)
            setAuctionDuration(259200)
          } else {
            toastError(
              'Error retrieving auction details. Please try again later.'
            )
          }
        } catch (error) {
          console.error('error retrieving auction details', error)
          toastError(
            'Error retrieving auction details. Please try again later.'
          )
        }
      } else {
        setNftOwner(tokenCreator)
        setAuctionId('')
        setAucHighestBid(0)
        setAucFirstBidTime(0)
        setAucEndTime(0)
        setAucReservePrice(0)
        setAuctionOwner('')
        setAucHighestBidder('')
        setAucCurrency('')
        setAucBuyNowPrice(0)
        setAuctionDuration(259200)
      }
    }
    const loadInternalNFTData = async () => {
      try {
        const detailsResponse = await getInternalNFTDetails(databaseId)
        if (detailsResponse.ok) {
          const responseJson = await detailsResponse.json()
          const mediaType =
            responseJson.mediaUri.indexOf('mp4') > 0 ? 'video' : 'image'

          setName(responseJson.name)
          setCollectionName(responseJson.collectionTitle)
          setAttributes(responseJson.attributes)
          setDesc(responseJson.description)
          setMediaUri(responseJson.mediaUri)
          setTokenUri(responseJson.tokenUri)
          setMediaType(mediaType)
          setLoading(false)
          setStorageFee(parseFloat(responseJson.storageFee))
          setNftCreator(responseJson.tokenCreatorAddress)
          setNftStatus(responseJson.status.toLowerCase())
          setChainTokenId(responseJson.tokenId)

          loadInternalAuctionData(
            responseJson.tokenId,
            responseJson.tokenCreatorAddress
          )
        } else {
          console.error('error with master metadata', detailsResponse.body)
          toastError('Error retrieving NFT details. Please try again later.')
        }
      } catch (error) {
        console.error('error retrieving nft details', error)
        toastError('Error retrieving NFT details. Please try again later.')
      }
    }

    loadInternalNFTData()
  }, [
    nftContract,
    databaseId,
    retries,
    marketContract,
    marketAddress,
    nftCollectionAddress,
    renderKey,
    cardMediaUri,
    toastError,
    chainId,
    isForSale,
    minted,
  ])

  const onDismiss = () => {
    setShowModal(false)
    setRenderKey(`${Math.random() * 10}`)
  }

  const onEndAuction = async () => {
    try {
      setLoading(true)
      const tx = await marketContract?.endAuction(auctionId)
      const result = await tx.wait(1)
      const auctionEndedEvent = result?.events?.find(
        (event) => event.args?.length && event.event === 'AuctionEnded'
      )
      const res = await createAuctionEvent({
        auctionId,
        tokenId: chainTokenId,
        tokenAddress: nftCollectionAddress,
        tokenOwnerAddress: auctionEndedEvent?.args?.winner,
        auctionCurrency: aucCurrency,
        duration: aucDuration.toString(),
        reservePrice: getDecimalAmount(
          new BigNumber(aucReservePrice)
        ).toString(),
        buyNowPrice: getDecimalAmount(new BigNumber(aucBuyNowPrice)).toString(),
        bidderAddress: auctionEndedEvent?.args?.winner,
        bidAmount: getDecimalAmount(new BigNumber(aucHighestBid)).toString(),
        status: 'CLOSED',
      })
      if (res.ok) {
        toastSuccess('Successfully finalized auction.')
        setRenderKey(`${Math.random() * 10}`)
      } else {
        throw new Error(
          'Error finalizing transaction. Please try again and confirm the transaction.'
        )
      }
    } catch (e) {
      toastError('Error', 'Please try again and confirm the transaction.')
    }
    setLoading(false)
  }

  const [onCreateAuction] = useModal(
    <ListingModal
      editMode={isForSale}
      onDismiss={onDismiss}
      dismiss={onDismiss}
      tokenName={tokenName}
      tokenContract={nftContract}
      marketContract={marketContract}
      tokenId={chainTokenId}
      tokenOwnerAddress={isTokenListed ? auctionOwner : nftOwner}
    />
  )

  const [onPlaceBid] = useModal(
    <BiddingModal
      onDismiss={onDismiss}
      dismiss={onDismiss}
      tokenName={tokenName}
      marketContract={marketContract}
      tokenId={chainTokenId}
      auctionId={auctionId}
      floorPrice={Math.max(aucReservePrice, aucHighestBid)}
      buyNowPrice={aucBuyNowPrice}
      auctionCurrency={aucCurrency}
      auctionDuration={aucDuration}
      tokenAddress={nftCollectionAddress}
      tokenOwnerAddress={nftOwner}
    />
  )

  return (
    <Container>
      <Heading> {collectionName} </Heading>
      <SubHeading> {tokenName} </SubHeading>
      <FlexContainer>
        <ImageCard>
          <CardBody style={{ textAlign: 'center' }}>
            {cardMediaType === 'video' ? (
              <video
                autoPlay
                loop
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                src={src}
              />
            ) : (
              <img alt="nft" src={src} />
            )}
          </CardBody>
        </ImageCard>
        <FlexElement>
          <CardWrapper>
            <CardBody style={{ textAlign: 'center' }}>
              {!isForSale && minted && (
                <span>{`Owned By: ${shortenAccount(nftOwner)}`}</span>
              )}
              {!minted && isCreator && (
                <>
                  <TextBody>Your NFT is ready to mint.</TextBody>
                  <TextBody>
                    Storage fee is ${storageFee.toFixed(2)} USD
                  </TextBody>
                  <Flex height="1em" />
                  <Btn
                    disabled={mintInProgress}
                    onClick={requestFileStoragePayment}
                  >
                    {mintInProgress ? 'PENDING' : 'MINT'}
                  </Btn>
                </>
              )}
              {!minted && !isCreator && (
                <Text>Created By: {shortenAccount(nftCreator)}</Text>
              )}
              {!account && <Text>Connect your wallet for more options</Text>}
              {isForSale && MARKETPLACE_READY && (
                <>
                  <TextHeading>Price</TextHeading>
                  <SubHeading>{floorPrice} BNB</SubHeading>
                  <TextBody fontSize="12px">
                    {Number(aucHighestBidder)
                      ? `Highest bid by ${shortenAccount(aucHighestBidder)}`
                      : 'No Bids Yet'}
                  </TextBody>
                  <TextHeading>Auction Time Left</TextHeading>
                  <TextBody>
                    {aucFirstBidTime ? (
                      <CountdownTimer endTime={new Date(aucEndTime)} />
                    ) : (
                      'Auction starts when first bid is received.'
                    )}
                  </TextBody>

                  <TextBody textAlign="center" paddingLeft="0px">
                    <br />
                    {auctionEnded ? (
                      <Btn disabled={loading} onClick={onEndAuction}>
                        {loading ? 'Loading... ' : 'Finalize Auction'}
                      </Btn>
                    ) : isAuctionOwner ? (
                      <Btn onClick={onCreateAuction}>Modify Listing</Btn>
                    ) : (
                      <Btn onClick={onPlaceBid}>Place Bid</Btn>
                    )}
                    <br />
                    {auctionEnded
                      ? 'SOLD'
                      : Number(aucBuyNowPrice) > 0 &&
                        `Buy Now Price: ${aucBuyNowPrice.toFixed(2)} BNB`}
                  </TextBody>

                  <TextHeading>
                    Listed By{' '}
                    {auctionOwner && (
                      <span style={{ fontWeight: 200 }}>
                        {shortenAccount(auctionOwner)}
                      </span>
                    )}
                  </TextHeading>
                </>
              )}
              {account === nftOwner && (
                <SubHeading>
                  <br />
                  Owned By You
                </SubHeading>
              )}
              {!isForSale && minted && (
                <SubHeading>
                  <br />
                  Not Currently For Sale
                </SubHeading>
              )}
              {minted &&
                !isForSale &&
                account?.toLowerCase() === nftOwner?.toLowerCase() &&
                MARKETPLACE_READY && (
                  <div style={{ textAlign: 'center' }}>
                    <br />
                    <Btn disabled={!MARKET_IS_READY} onClick={onCreateAuction}>
                      {' '}
                      List for Sale{' '}
                    </Btn>
                  </div>
                )}
            </CardBody>
          </CardWrapper>
          <br />
          <CardWrapper>
            <CardBody>
              <SubHeading left> Description </SubHeading>
              <br />
              {desc}
              <hr style={{ margin: '30px' }} />
              <SubHeading left> Attributes </SubHeading>
              {Object.keys(collectionAttributes).length ? (
                <NftCollectionAttributes
                  collectionAttributes={collectionAttributes}
                  cardAttributes={cardAttributes}
                />
              ) : cardAttributes?.length ? (
                cardAttributes.map((attr: any) => {
                  return (
                    <NftAttribute
                      key={attr.trait_type}
                      attribute={{
                        attrName: attr.trait_type,
                        attrValue: attr.value,
                      }}
                    />
                  )
                })
              ) : (
                'No Attributes'
              )}
            </CardBody>
          </CardWrapper>
        </FlexElement>
      </FlexContainer>
    </Container>
  )
}
