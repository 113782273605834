import React, { useState, useMemo, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'hooks/useContract'
import { Contract } from '@ethersproject/contracts'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Card, Text, CardBody } from '@pancakeswap-libs/uikit'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import * as NftMarketplace from 'config/abis/NftMarketplace.json'
import { marketAddressByChain } from 'config'
import { ArrowWrapper, BottomGrouping, Wrapper } from 'components/swap/styleds'
import Container from 'components/Container'
import CountdownTimer from 'components/CountdownTimer'
import CardNav from 'components/CardNav'

import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoRow, RowBetween } from 'components/Row'

import NftCardHeader from './NftCardHeader'

export const CardWrapper = styled(Card)<{ small?: boolean }>`
  position: relative;
  @media only screen and (max-width: 780px) {
    min-width: ${({ small }) => (small ? '70vw' : '90vw')};
  }
  width: ${({ small }) => (small ? '20vw' : '28vw')};
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(29, 162, 231);
  box-shadow: rgb(0 146 224) 0px 0px 1.5rem,
    rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
`

const FlexElement = styled.div`
  padding: 10px;
  flex: 1;
  justify-content: center;
`
const CardBottomFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const NftCard = ({
  id,
  contract,
  endTime,
  price,
  small,
  linkOpensNewWindow,
}: {
  id: number
  contract: Contract | string
  endTime?: Date
  price?: string
  linkOpensNewWindow?: boolean
  small?: boolean
}) => {
  const { account, chainId } = useActiveWeb3React()

  const [loading, setLoading] = useState(true)
  const [cardName, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [cardMediaType, setMediaType] = useState('')
  const [cardMediaUri, setMediaUri] = useState('')
  const [retries, setRetries] = useState(0)

  const nftContract = useContract(
    typeof contract === 'string' ? contract : contract.address,
    GalaxyHeroesNFT.abi,
    true
  )

  const marketContract = useContract(
    marketAddressByChain[chainId || '56'],
    NftMarketplace.abi,
    true
  )

  useEffect(() => {
    const loadData = async () => {
      try {
        let jsonUri = await nftContract?.callStatic.tokenURI(id)

        jsonUri = jsonUri.replace('ipfs://', process.env.REACT_APP_IPFS_GATEWAY)
        const resp = await (await fetch(jsonUri)).json()
        const { name, description, image } = resp
        const mediaType = image.indexOf('mp4') > 0 ? 'video' : 'image'
        const mediaUri = image.replace(
          'ipfs://',
          process.env.REACT_APP_IPFS_GATEWAY
        )
        setName(name)
        setDesc(description)
        setMediaUri(mediaUri)
        setMediaType(mediaType)
        setLoading(false)
      } catch (e) {
        if (retries < 5) {
          setRetries(retries + 1)
        }
      }
    }
    loadData()
  }, [nftContract, id, retries])

  const src =
    cardMediaUri || `${process.env.REACT_APP_PUBLIC_URL}/images/new_loading.gif`

  const auctionEnded = endTime && endTime.getTime() < new Date().getTime()

  return (
    <FlexElement>
      <a
        target={linkOpensNewWindow ? '_blank' : undefined}
        rel="noreferrer"
        href={`#/nftpdp/${nftContract?.address}/${id}`}
      >
        <CardWrapper small={small}>
          <Wrapper id="swap-page">
            <>
              <NftCardHeader
                title={loading ? 'Loading' : cardName}
                description={!small && (loading ? 'Loading' : desc)}
              />
              <CardBody style={{ textAlign: 'center' }}>
                {cardMediaType === 'video' ? (
                  <>
                    {/** @es-ignore */}
                    <video
                      autoPlay
                      muted
                      loop
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      src={src}
                    />
                  </>
                ) : (
                  <img alt="nft" src={src} />
                )}
                <AutoColumn gap="md" />
                <BottomGrouping>
                  <CardBottomFlex>
                    {price && (
                      <>
                        <div style={{ textAlign: 'left' }}>
                          <Text fontWeight="800">Lowest Price</Text>
                          <Text>{price} BNB</Text>
                        </div>

                        {endTime ? (
                          auctionEnded ? (
                            <div style={{ textAlign: 'right' }}>
                              <Text fontWeight="800">Status</Text>
                              <Text color="rgba(50,205,50,0.8)">SOLD</Text>
                            </div>
                          ) : (
                            <div style={{ textAlign: 'right' }}>
                              <Text fontWeight="800">Time Remaining</Text>
                              <CountdownTimer endTime={endTime} />
                            </div>
                          )
                        ) : (
                          <div style={{ textAlign: 'right' }}>
                            <Text fontWeight="800">Status</Text>
                            <Text color="lightgreen">Auction Live</Text>
                          </div>
                        )}
                      </>
                    )}
                  </CardBottomFlex>
                </BottomGrouping>
              </CardBody>
            </>
          </Wrapper>
        </CardWrapper>
      </a>
    </FlexElement>
  )
}

export default NftCard
