import { ethers } from 'ethers'
import { map } from 'lodash'
import { apiUrl } from 'connectors'

interface AuctionState {
  auctionId: string
  tokenId: string
  tokenAddress: string
  tokenOwnerAddress: string
  auctionCurrency: string
  duration: string
  reservePrice: string
  buyNowPrice: string
  bidderAddress?: string | null | undefined
  bidAmount?: string | null | undefined
  status: string
}

const fetchRetry = async (url, options, n) => {
  const res = await fetch(url, options)
  if (!res.ok) {
    if (n === 1) return res

    return fetchRetry(url, options, n - 1)
  }
  return res
}

export const deposit = async (farmContract, pid, amount, account) => {
  return farmContract.methods.deposit(pid, amount).send({ from: account })
}

export const approve = async (lpContract, masterChefContract) => {
  return lpContract.approve(
    masterChefContract.options.address,
    ethers.constants.MaxUint256
  )
}

export const mintCustomNft = async (
  name,
  description,
  attributes,
  asset,
  storageFee,
  owner
) => {
  const formData = new FormData()
  formData.set('name', name)
  formData.set('description', description)
  formData.set('storageFee', storageFee)
  formData.set('tokenCreatorAddress', owner)
  if (attributes?.length) {
    formData.set(
      'attributes',
      JSON.stringify(
        map(attributes, (attribute) => {
          return {
            trait_type: attribute.key,
            value: attribute.value,
          }
        })
      )
    )
  }

  formData.set('mediaFile', asset)

  const requestOptions = {
    method: 'POST',
    body: formData,
  }
  return fetch(`${apiUrl}/tokens`, requestOptions)
}

export const getInternalNFTDetails = async (id: string) => {
  return fetch(`${apiUrl}/tokens/${id}`)
}

export const getAuctionSummaryForNft = async (tokenId: string) => {
  return fetch(`${apiUrl}/auctions/${tokenId}/summary`)
}

export const createAuctionEvent = async (bid: AuctionState) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bid),
  }
  return fetch(`${apiUrl}/auctions/event`, requestOptions)
}

export const validateNftStorageFeeTransaction = async (databaseId, receipt) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      receipt,
    }),
  }
  const res = await fetchRetry(
    `${apiUrl}/tokens/${databaseId}/validate-storage-transaction`,
    requestOptions,
    1000
  )
  return res
}

export const updateNftStatus = async (tokenId, chainTokenId, status) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      tokenId: chainTokenId,
      status,
    }),
  }
  return fetch(`${apiUrl}/tokens/${tokenId}`, requestOptions)
}
