import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useActiveWeb3React } from 'hooks'
import { useWalletModal } from '@pancakeswap-libs/uikit'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import { useContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import { CardBody, Flex, Text } from '@pancakeswap-libs/uikit'
import { ArrowWrapper, BottomGrouping, Wrapper } from 'components/swap/styleds'
import Container from 'components/Container'
import CardNav from 'components/CardNav'
import CountdownTimer from 'components/CountdownTimer'
import ImageFlasher from 'components/ImageFlasher'
import { AutoColumn } from 'components/Column'
import GlowHeading from 'components/GlowHeading'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import { nftAddressByChain } from 'config'
import NftPageHeader from 'components/NftPageHeader'
import CustomNftMint from 'components/CustomNftMint'
import AppBody from '../AppBody'

const LAUNCH_TIME = new Date('2021-11-30T21:00:00-05:00')

export const Btn = styled.button`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: rgb(29, 162, 231);
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: rgba(178, 96, 220, 0.8);
  }
  &:disabled {
    background-color: rgba(100, 100, 100, 0.4);
    cursor: none;
  }
`

const MintNfts = () => {
  return (
    <Container>
      <CustomNftMint />
    </Container>
  )
}

export default MintNfts
