import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

    .colorTheme {
      color: rgb(29, 162, 231) !important;
    }
    
    .bgTheme {
      background-color: rgb(29, 162, 231) !important;
    }
    
    .primaryBtn {
      background-color: rgb(29, 162, 231) !important;
      color: white;
    }
    
    .tertiaryBtn {
      color: rgb(29, 162, 231);
      background-color: #353547;
    }

    svg {
      fill: rgb(29, 162, 231) !important;
      color: rgb(29, 162, 231);
      stroke: #6b0bba;
    }

    #token-search-input:focus {
      border-color: rgb(29, 162, 231);
    }

    a {
      color: rgb(29, 162, 231); 
      fill: rgb(29, 162, 231);
    }

    div[color="primary"], div[color="#1FC7D4"],
    a[variant="primary"] {

    }
  }

  ul {
    list-style: none; 
  }

  li {
    display: flex;
    align-items: center;
  }

  li::before {
    content: "•";
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 8px;
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown__content {
      display: none;
      position: absolute;
  }

  .dropdown--active .dropdown__content {
      display: block;
  }

`

export default GlobalStyle
