import BigNumber from 'bignumber.js'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, LinkExternal, Text } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { useContract } from 'hooks/useContract'
import useToast from 'hooks/useToast'
import { nftAddressByChain } from 'config/index'
import { MouseoverTooltip } from 'components/Tooltip'
import ModalActions from 'components/ModalActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import ModalInput from 'components/ModalInput'
import NftCard from 'components/NftCard/index'
import { getFullDisplayBalance } from 'utils/formatBalance'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import { nftStakingFarmByChain } from 'pages/Farms/data'
import { useActiveWeb3React } from '../../hooks'
import * as FarmContractV2 from '../../config/abis/HighstackNftFarmCerts.json'

interface DepositModalProps {
  max: BigNumber
  onConfirm: (tokenId: string) => void
  onDismiss: () => void
  onEmergencyWithdraw?: () => void
  tokenName?: string
  addLiquidityUrl?: string
  title?: string
}

const CustomButton = styled(Button)`
  background-color: ${(props) =>
    props.variant === 'secondary' ? 'white' : 'rgb(29, 162, 231)'};
  color: ${(props) =>
    props.variant === 'secondary' ? 'rgb(29, 162, 231)' : 'white'};
  border: ${(props) =>
    props.variant === 'secondary' ? '1px solid rgb(29, 162, 231)' : 'none'};
`
const LittleButton = styled(CustomButton)`
  background-color: rgb(173, 57, 67);
  height: 30px;
  font-size: 12px;
`
const CustomLinkExternal = styled(LinkExternal)`
  color: rgba(178, 96, 220, 1);
`

const TextButton = styled(Button)`
  padding: 0;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const DepositNftModal: React.FC<DepositModalProps> = ({
  max,
  onConfirm,
  onDismiss,
  onEmergencyWithdraw,
  tokenName = '',
  title = '',
  addLiquidityUrl,
}) => {
  const { toastSuccess, toastError } = useToast()
  const { account, chainId } = useActiveWeb3React()
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const [loading, setLoading] = useState(true)
  const [farmEnabled, setFarmEnabled] = useState(false)
  const [userNftHoldings, setUserNftHoldings] = useState<any>([])
  const [selectedNftIndex, setSelectedNftIndex] = useState(0)

  const nftAddress =
    nftAddressByChain[chainId || '56'] || nftAddressByChain['56']
  const nftContract = useContract(nftAddress, GalaxyHeroesNFT.abi, true)

  const farmAddress =
    nftStakingFarmByChain[chainId || 56] || nftStakingFarmByChain['56']
  const farmContract = useContract(farmAddress, FarmContractV2.abi, true)

  useEffect(() => {
    const loadData = async () => {
      if (account) {
        const usersTokens = await nftContract?.callStatic.walletOfOwner(account)
        setUserNftHoldings(usersTokens)
        const farmAllowance = await nftContract?.callStatic.isApprovedForAll(
          account,
          farmAddress
        )
        setFarmEnabled(farmAllowance)
        setLoading(false)
      }
    }
    loadData()
  }, [nftContract?.callStatic, account, farmAddress])


  const onCycleLeft = () => {
    const newIndex = selectedNftIndex - 1
    setSelectedNftIndex(
      newIndex < 0 ? userNftHoldings.length - Math.abs(newIndex) : newIndex
    )
  }

  const onCycleRight = () => {
    setSelectedNftIndex(Math.abs(selectedNftIndex + 1) % userNftHoldings.length)
  }

  const onApprove = async () => {
    const isEnabled = await nftContract?.setApprovalForAll(farmAddress, true)
    await isEnabled.wait(1)
    toastSuccess('NFTs Enabled', 'You can now stake!')
  }

  const userHasMultiple = userNftHoldings.length > 1
  return (
    <Modal title="Select NFT to Stake" onDismiss={onDismiss}>
      <Flex>
        {userHasMultiple && (
          <TextButton variant="text" onClick={onCycleLeft}>
            <FontAwesomeIcon size="2x" icon={faChevronLeft} />
          </TextButton>
        )}
        {userNftHoldings.length > 0 ? (
          <NftCard
            small
            linkOpensNewWindow
            contract={nftAddress}
            id={userNftHoldings[selectedNftIndex]}
          />
        ) : (
          <Text>You have no NFTs to stake.</Text>
        )}
        {userHasMultiple && (
          <TextButton variant="text" onClick={onCycleRight}>
            <FontAwesomeIcon size="2x" icon={faChevronRight} />
          </TextButton>
        )}
      </Flex>
      <ModalActions>
        <CustomButton
          variant="secondary"
          onClick={onDismiss}
          width="100%"
          disabled={pendingTx}
        >
          Cancel
        </CustomButton>
        {farmEnabled ? (
          <CustomButton
            width="100%"
            disabled={pendingTx || userNftHoldings.length === 0}
            onClick={async () => {
              setPendingTx(true)
              await onConfirm(userNftHoldings[selectedNftIndex])
              setPendingTx(false)
              onDismiss()
            }}
          >
            {pendingTx ? 'Pending Confirmation' : 'Confirm'}
          </CustomButton>
        ) : (
          <CustomButton
            width="100%"
            disabled={pendingTx || userNftHoldings.length === 0}
            onClick={async () => {
              setPendingTx(true)
              await onApprove()
              setPendingTx(false)
            }}
          >
            {pendingTx ? 'Pending Confirmation' : 'Approve'}
          </CustomButton>
        )}
      </ModalActions>
      <CustomLinkExternal
        href={addLiquidityUrl}
        style={{ alignSelf: 'center' }}
      >
        Get More {tokenName}
      </CustomLinkExternal>
    </Modal>
  )
}

export default DepositNftModal
