/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import styled from 'styled-components'
import { Card, CardBody, Button } from '@pancakeswap-libs/uikit'
import Container from 'components/Container'
import { Link } from 'react-router-dom'
import { SubHeading, Heading} from './components'

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100vh;
  max-width: 100%;
  justify-content: top;
`
const CardFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 5px;
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(29, 162, 231);
  box-shadow: rgb(0 146 224) 0px 0px 1.5rem,
    rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  text-align: left;
`
const ImageCard = styled(CardWrapper)`
  width: 100%;
  max-width: 60vh;
  margin-top: 30px;
`

const FlexElement = styled.div`
  @media only screen and (max-width: 780px) {
    padding: 10px;
  }
  padding: 30px;
  flex: 1;
  justify-content: center;
`

const NftDetail = ({ location: { 
  state: {
      name,
      collectionName,
      collectionSymbol,
      collectionAddress,
      tokenId,
      description,
      mediaUri = 'https://freesvg.org/img/ftfile-broken.png',
      mediaType = 'image',
      attributes,
      contractType,
    }
  }
}) => {

  return (
    <Container>
      <Heading> {collectionName} </Heading>
      <SubHeading> {name} </SubHeading>
      <FlexContainer>
        <ImageCard>
          <CardBody style={{ textAlign: 'center' }}>
            {mediaType === 'video' ? (
              <>
                {/** @es-ignore */}
                <video
                  autoPlay
                  loop
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  src={mediaUri}
                />
              </>
            ) : (
              <img alt="nft" src={mediaUri} />
            )}
          </CardBody>
        </ImageCard>
        <FlexElement>
          <CardWrapper>
            <CardBody style={{ textAlign: 'center' }}>
                <SubHeading>
                  <br />
                    Not Currently For Sale
                </SubHeading>
            </CardBody>
          </CardWrapper>
          <br />
          <CardWrapper>
            <CardBody>
              <SubHeading left> Description </SubHeading>
              <br />
              {description}
              <hr style={{ margin: '15px' }} />
              <SubHeading left> Collection </SubHeading>
              <br />
              <Link to={{
                pathname: `/nftcollection/${collectionAddress}`}}
                >
                  {collectionName} ({collectionSymbol}) {collectionAddress}
              </Link>
              <hr style={{ margin: '15px' }} />
              <SubHeading left> Token ID </SubHeading>
              <br />
              {tokenId}
              <hr style={{ margin: '20px' }} />
              <SubHeading left> Attributes </SubHeading>
              <br />
              {attributes.length ? (
                <>
                  {attributes.map((attr: any) => {
                    const attrName = attr.trait_type.replace(/[^a-zA-Z ]/g, '')
                    const value = attr.value.replace('_', ' ')
                    return (
                      <CardFlex key={attrName}>
                        <div>{attrName}</div>
                        <div>{value}</div>
                      </CardFlex>
                    )
                  })}
                  <br/>
                </>
              ) : (
                'No Attributes'
              )}
            </CardBody>
          </CardWrapper>
        </FlexElement>
      </FlexContainer>
    </Container>
  )
}

export default NftDetail

export const Btn = styled(Button)`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: rgb(29, 162, 231);
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: rgba(178, 96, 220, 0.8);
  }
`
