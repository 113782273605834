import React, { useEffect, useCallback, useMemo } from 'react'
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from 'react-moralis'
import Pagination from 'rc-pagination'
import styled from 'styled-components'
import { Card } from '@pancakeswap-libs/uikit'
import Container from 'components/Container'
import CardNav from 'components/CardNav'
import NftPageHeader from 'components/NftPageHeader'
import NftCardGrid from 'components/NftCardGrid'
import './pagination.css'

const ITEMS_PER_PAGE = 15

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: center;
`

export const Heading = styled.div`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.topMargin ? '30px' : '0')};
  color: black;
  filter: drop-shadow(3px 1px 1px white);
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  width: 450px;
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(29, 162, 231);
  box-shadow: rgb(0 146 224) 0px 0px 1.5rem,
    rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  overflow: visible;
`

const NftCollection = ({
  match: {
    params: { nftCollectionAddress },
  },
}) => {
  const Web3Api = useMoralisWeb3Api()

  const { fetch, data, error, isLoading } = useMoralisWeb3ApiCall(
    Web3Api.token.getAllTokenIds,
    {
      chain: 'bsc',
      address: nftCollectionAddress,
      limit: ITEMS_PER_PAGE,
    }
  )

  const onChangePage = useCallback(
    (newPage) => {
      fetch({ params: { offset: newPage * ITEMS_PER_PAGE } })
    },
    [fetch]
  )

  const title = useMemo(
    () => data?.result?.at(0)?.name || 'NFT Collection',
    [data]
  )

  useEffect(() => {
    fetch()
  }, [fetch])

  if (error) {
    console.error(error)
    return <div>An error occurred</div>
  }

  return (
    <Container>
      <Heading>{title}</Heading>
      <CardNav />
      <CardWrapper style={{ zIndex: 100 }}>
        <NftPageHeader
          title="Explore Collection"
          description="Explore NFTs in collection"
        />
      </CardWrapper>
      <hr />
      {isLoading || !data ? (
        <FlexContainer>Loading...</FlexContainer>
      ) : (
        <NftCardGrid nftList={data?.result} />
      )}
      {data?.total > ITEMS_PER_PAGE && (
        <div style={{ lineHeight1: '25px' }}>
          <Pagination
            className="ant-pagination"
            pageSize={data?.page_size}
            current={data?.page + 1}
            total={data?.total}
            onChange={(pageNumber) => onChangePage(pageNumber - 1)}
          />
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
    </Container>
  )
}

export default NftCollection
