import React, { useEffect, useState } from 'react'
import { Contract } from '@ethersproject/contracts'
import styled from 'styled-components'
import { Card, Text, CardBody } from '@pancakeswap-libs/uikit'
import { BottomGrouping, Wrapper } from 'components/swap/styleds'

import { AutoColumn } from 'components/Column'

import { Link } from 'react-router-dom'
import NftCardHeader from './NftCardHeader'

export const CardWrapper = styled(Card)<{ small?: boolean }>`
  position: relative;
  @media only screen and (max-width: 780px) {
    min-width: ${({ small }) => (small ? '70vw' : '90vw')};
  }
  width: ${({ small }) => (small ? '20vw' : '28vw')};
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(29, 162, 231);
  box-shadow: rgb(0 146 224) 0px 0px 1.5rem,
    rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
`

const FlexElement = styled.div`
  padding: 10px;
  flex: 1;
  justify-content: center;
`
const CardBottomFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const LOADING_IMAGE = `${process.env.REACT_APP_PUBLIC_URL}/images/new_loading.gif`

const NftCardV2 = ({
  id,
  contract,
  endTime,
  price,
  small,
  title,
  description,
  mediaUri,
  mediaType = 'image',
  attributes,
  linkOpensNewWindow,
  tokenUri,
  collectionName,
  collectionSymbol,
  contractType,
}: {
  id: number
  contract: Contract | string
  endTime?: Date
  price?: string
  title?: string
  description?: string
  mediaUri?: string
  mediaType?: string
  attributes?: any
  linkOpensNewWindow?: boolean
  small?: boolean
  tokenUri?: string
  collectionName?: string
  collectionSymbol?: string
  contractType?: string
}) => {
  const [metadata, setMetadata] = useState({
    image: mediaUri,
    name: '',
    description: '',
    attributes: {},
  })
  const src =
    metadata?.image?.replace(
      'ipfs://',
      process.env.REACT_APP_IPFS_GATEWAY || ''
    ) || LOADING_IMAGE
  const name = metadata?.name || title
  const details = metadata?.description || description
  const attrs = attributes || metadata?.attributes

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = tokenUri && (await fetch(tokenUri, { mode: 'cors' }))
        setMetadata(response && (await response.json()))
      } catch (e) {
        console.error(e)
        setMetadata({
          image: 'https://freesvg.org/img/ftfile-broken.png',
          name: '',
          description: '',
          attributes: {},
        })
      }
    }
    if (!mediaUri) {
      fetchMetadata()
    }
  }, [mediaUri, tokenUri])

  return (
    <FlexElement>
      <CardWrapper small={small}>
        <Wrapper id="swap-page">
          <>
            <Link
              to={{
                pathname: `/nft/${contract}/${id}`,
                state: {
                  name,
                  collectionName,
                  collectionSymbol,
                  collectionAddress: contract,
                  tokenId: id,
                  description: details,
                  mediaUri: src,
                  mediaType,
                  contractType,
                  attributes: attrs,
                },
              }}
            >
              <NftCardHeader title={name} description={details} />
            </Link>
            <CardBody style={{ textAlign: 'center' }}>
              <Link
                to={{
                  pathname: `/nft/${contract}/${id}`,
                  state: {
                    name,
                    collectionName,
                    collectionSymbol,
                    collectionAddress: contract,
                    tokenId: id,
                    description: details,
                    mediaUri: src,
                    mediaType,
                    contractType,
                    attributes: attrs,
                  },
                }}
              >
                {mediaType === 'video' ? (
                  <>
                    {/** @es-ignore */}
                    <video
                      autoPlay
                      muted
                      loop
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                      src={src}
                    />
                  </>
                ) : (
                  <img alt="nft" src={src} />
                )}
              </Link>
              <AutoColumn gap="md" />
              <BottomGrouping>
                <a
                  target={linkOpensNewWindow ? '_blank' : undefined}
                  rel="noreferrer"
                  href={`#/nftcollection/${contract}`}
                >
                  <CardBottomFlex>
                    <div style={{ textAlign: 'left' }}>
                      <Text fontWeight="800">Collection</Text>
                      <Text>{`${collectionName} (${collectionSymbol})`}</Text>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Text fontWeight="800">Type</Text>
                      <Text>{contractType}</Text>
                    </div>
                  </CardBottomFlex>
                </a>
              </BottomGrouping>
            </CardBody>
          </>
        </Wrapper>
      </CardWrapper>
    </FlexElement>
  )
}

export default NftCardV2
