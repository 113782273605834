import React from 'react'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'

import { Provider } from 'react-redux'
import { ModalProvider } from '@pancakeswap-libs/uikit'
import { MoralisProvider } from 'react-moralis'
import { NetworkContextName } from './constants'
import store from './state'
import getLibrary from './utils/getLibrary'
import { ThemeContextProvider } from './ThemeContext'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
const moralisAppId = process.env.REACT_APP_MORALIS_APP_ID || ''
const moralisServerUrl = process.env.REACT_APP_MORALIS_SERVER_URL || ''

const StandardProviders: React.FC = ({ children }) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      <Provider store={store}>
        <ThemeContextProvider>
          <ModalProvider>{children}</ModalProvider>
        </ThemeContextProvider>
      </Provider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>
)

const Providers: React.FC = ({ children }) =>
  moralisAppId && moralisServerUrl ? (
    <MoralisProvider appId={moralisAppId} serverUrl={moralisServerUrl}>
      <StandardProviders>{children}</StandardProviders>
    </MoralisProvider>
  ) : (
    <StandardProviders>{children}</StandardProviders>
  )

export default Providers
