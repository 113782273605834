import BigNumber from 'bignumber.js'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS = {
  56: 'https://bscscan.com',
  97: 'https://testnet.bscscan.com',
}

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 40
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://pancakeswap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[56]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'

/* * NFT ADDRESSES * */

export const nftAddressByChain = {
  56: '0xa86A3477bDcFb5194c711968aDadAa73091CfE30',
  97: '0x3A2B4e6221EaEdf5e512bAac7D7882BFC9578ee3',
}

export const customNftAddressByChain = {
  56: '0xF257F6A857B5fBECdF135A6C9b0ad3608e483268',
  97: '0x43cfbea2E936a1B7Fe4916e525378c9Fb4E5a8BF',
}

export const marketAddressByChain = {
  56: '0xF06d206020Ef16fa3ED24beb8Ae614ec64e94cB7',
  97: '0x11E24bE4cAbd8109dC44889E73f1eEa678B228A0',
}

export const bridgeAddressByChain = {
  56: '0xad65e755412e76CC48E7d9f104c58682823ad2e6',
  97: '',
  1: '0x27C1D6829Ba9C1c3C7Fa6e5E22502F752dABe7cC',
}
