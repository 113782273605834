/**
 * Take an addreess and add elipsis center of it.
 */
export const shortenAccount = (account: string) => {
  return `${account.slice(0, 6)}...${account.slice(
    account.length - 4,
    account.length - 1
  )}`
}

export default shortenAccount
