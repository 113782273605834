import BigNumber from 'bignumber.js'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, LinkExternal, Text } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { useActiveWeb3React } from 'hooks'
import { createAuctionEvent } from 'utils/callHelpers'
import { MouseoverTooltip } from 'components/Tooltip'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import {
  getBalanceNumber,
  getDecimalAmount,
  getFullDisplayBalance,
} from 'utils/formatBalance'
import useToast from 'hooks/useToast'
import { useETHBalances } from 'state/wallet/hooks'
import { customNftAddress } from 'connectors'
import { SubHeading, Heading, TextBody, TextHeading } from './components'

interface ListingModalProps {
  onDismiss: () => void
  tokenId: string
  tokenName: string
  tokenContract: any
  marketContract: any
}

const CustomButton = styled(Button)`
  background-color: ${(props) =>
    props.variant === 'secondary' ? 'white' : 'rgb(29, 162, 231)'};
  color: ${(props) =>
    props.variant === 'secondary' ? 'rgb(29, 162, 231)' : 'white'};
  border: ${(props) =>
    props.variant === 'secondary' ? '1px solid rgb(29, 162, 231)' : 'none'};
  margin: 0px 5px;
`
const LittleButton = styled(CustomButton)`
  background-color: rgb(173, 57, 67);
  height: 30px;
  font-size: 12px;
`
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

const BiddingModal: React.FC<any> = ({
  onDismiss,
  dismiss,
  auctionId,
  tokenName,
  tokenId,
  marketContract,
  tokenAddress,
  tokenOwnerAddress,
  auctionCurrency,
  auctionDuration,
  floorPrice = 0.35,
  buyNowPrice = 1,
}) => {
  const minBid = floorPrice * 1.07
  const [bidValue, setBidValue] = useState(minBid.toString())
  const [pendingTx, setPendingTx] = useState(false)
  const [inputError, setInputError] = useState('')
  const [bnbBalance, setBnbBalance] = useState('')
  const { toastSuccess, toastError } = useToast()
  const { account } = useActiveWeb3React()

  const ethBalance = useETHBalances(account ? [account] : undefined)
  useEffect(() => {
    if (ethBalance[account || ''] !== undefined) {
      // @ts-ignore
      setBnbBalance(ethBalance[account || ''].toFixed(4))
    }
  }, [ethBalance, account, setBnbBalance])

  const handleBidChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        const value = e.currentTarget.value.replace(/,/g, '.')
        if (Number(value) < minBid) {
          setInputError(`Minimum bid of ${minBid.toFixed(4)} required`)
        } else {
          setInputError('')
        }
        setBidValue(value)
      }
    },
    [setBidValue, minBid]
  )

  const onPlaceBid = async (bidPrice) => {
    try {
      const tx = await marketContract.createBid(
        auctionId,
        bidPrice.toString(),
        { value: bidPrice.toString() }
      )

      const result = await tx.wait(1)

      if (customNftAddress === tokenAddress) {
        const auctionEndedEvent = result?.events?.find(
          (event) => event.args?.length && event.event === 'AuctionEnded'
        )
        const auctionExtendedEvent = result?.events?.find(
          (event) =>
            event.args?.length && event.event === 'AuctionDurationExtended'
        )
        const newDuration =
          auctionExtendedEvent?.args?.duration || auctionDuration

        const res = await createAuctionEvent({
          auctionId,
          tokenId,
          tokenAddress,
          tokenOwnerAddress: auctionEndedEvent
            ? auctionEndedEvent?.args?.winner
            : tokenOwnerAddress,
          auctionCurrency,
          duration: newDuration.toString(),
          reservePrice: getDecimalAmount(floorPrice).toString(),
          buyNowPrice: getDecimalAmount(buyNowPrice).toString(),
          bidderAddress: account,
          bidAmount: bidPrice.toString(),
          status: auctionEndedEvent ? 'CLOSED' : 'OPEN',
        })
        if (res.ok) {
          toastSuccess('Successfully submitted bid.')
        } else {
          throw new Error('Error submitting bid')
        }
      }
      // TODO what should happen if bid is recorded on blockchain but not on the internal backend?
    } catch (e) {
      console.error(e, auctionId, bidPrice)
      toastError(
        'Error',
        `Please try again and confirm the transaction. ${
          (e as any)?.data?.message
        }`
      )
    }
  }

  const handleSelectMax = useCallback(() => {
    if (bnbBalance) {
      setBidValue((Number(bnbBalance) - 0.002).toString())
    }
  }, [bnbBalance, setBidValue])
  const bidValNumber = new BigNumber(Number(bidValue) * 1e18)
  const buyNowValNumber = new BigNumber(Number(buyNowPrice) * 1e18)
  return (
    <>
      <Modal title={`Place Bid on ${tokenName}`} onDismiss={onDismiss}>
        {!!buyNowPrice && (
          <>
            <TextHeading textAlign="center">Buy Now for</TextHeading>
            <br />
            <SubHeading>{`${buyNowPrice.toFixed(4)} BNB`}</SubHeading>

            <TextHeading textAlign="center">OR</TextHeading>
          </>
        )}
        <br />
        <ModalInput
          value={bidValue}
          minimum={floorPrice}
          onSelectMax={handleSelectMax}
          onChange={handleBidChange}
          max={bnbBalance || undefined}
          allowZeroBalance
          symbol="BNB"
          inputTitle="Place Bid"
          decimals={18}
        />
        <Text fontSize="14px" color="red" textAlign="center">
          {inputError}
        </Text>
        <div style={{ display: 'flex', padding: 24 }}>
          <CustomButton
            variant="subtle"
            width="100%"
            disabled={
              pendingTx ||
              !bidValNumber.isFinite() ||
              bidValNumber.eq(0) ||
              inputError
            }
            onClick={async () => {
              setPendingTx(true)
              await onPlaceBid(bidValNumber)
              setPendingTx(false)
              onDismiss()
              dismiss()
            }}
          >
            {pendingTx ? 'Pending...' : 'Place Bid'}
          </CustomButton>
          {!!buyNowPrice && (
            <CustomButton
              variant="subtle"
              width="100%"
              disabled={pendingTx}
              onClick={async () => {
                setPendingTx(true)
                await onPlaceBid(buyNowValNumber)
                setPendingTx(false)
                onDismiss()
                dismiss()
              }}
            >
              {pendingTx ? 'Pending...' : 'Buy Now'}
            </CustomButton>
          )}
        </div>
        <Text fontSize="12px">
          * Your bid must be 5% more than the current bid or floor price.
          <br />* You cannot withdraw a bid once submitted.
          <br /> * Funds are returned to your wallet if you are outbid
        </Text>
      </Modal>
    </>
  )
}

export default BiddingModal
