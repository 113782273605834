import styled from 'styled-components'

const CardFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 5px;
`

export default CardFlex
