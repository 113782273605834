import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ASSET_STORAGE_RATE } from '../../constants'

interface NftAttribute {
  id: number
  key: string
  value: string
}

interface Asset {
  name: string
  size: number
  type: string
  url: string | ArrayBuffer | null
}

interface CustomNftState {
  name: string
  description: string
  attributesList: NftAttribute[]
  asset: Asset | null
  storageFee: number | null
  status: string
}

const mintCustomNftSlice = createSlice({
  name: 'mintCustomNft',
  initialState: {
    name: '',
    description: '',
    attributesList: [] as NftAttribute[],
    asset: null,
    storageFee: null,
    status: '',
  },
  reducers: {
    setName: (state: CustomNftState, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setDescription: (state: CustomNftState, action: PayloadAction<string>) => {
      state.description = action.payload
    },
    setAttributesList: (
      state: CustomNftState,
      action: PayloadAction<NftAttribute[]>
    ) => {
      state.attributesList = action.payload
    },
    setAsset: (state: CustomNftState, action: PayloadAction<Asset>) => {
      state.asset = action.payload
      state.storageFee = 1e-6 * action.payload.size * ASSET_STORAGE_RATE
    },
    setStatus: (state: CustomNftState, action: PayloadAction<string>) => {
      state.status = action.payload
    },
    clearCustomNftState: (state: CustomNftState) => {
      state.name = ''
      state.description = ''
      state.attributesList = []
      state.asset = null
      state.status = ''
    },
  },
})

export const {
  setName,
  setDescription,
  setAttributesList,
  setAsset,
  setStatus,
  clearCustomNftState,
} = mintCustomNftSlice.actions
export default mintCustomNftSlice.reducer
