export interface FarmType {
  token1?: string
  token2?: string
  tokenOverride?: string
  symbol?: string
  addressByChain: any
  poolAddress: string
  decimals: number
  reward: any
}

export const farmData: FarmType[] = [
  {
    token1: 'GHC',
    decimals: 9,
    addressByChain: {
      '56': '0x683fae4411249ca05243dfb919c20920f3f5bfe0',
    },
    poolAddress: '0x8De88D5298D85487EBdcBBe552f4Ba62C51ba945',
    reward: {
      address: '0x9AD44e8Bf6C485dB08b041185a05c0d16D16069c',
      name: 'GHE',
      decimals: 18,
    },
  },
  {
    token1: 'GHC',
    token2: 'BNB',
    decimals: 18,
    addressByChain: {
      '56': '0xcFD9ee6D907f62f03942cA2e6f94f40e35d29c2F',
    },
    poolAddress: '0x563D6232F317722E5156b72f4C93d42C2021Ee5d',
    reward: {
      address: '0x9AD44e8Bf6C485dB08b041185a05c0d16D16069c',
      name: 'GHE',
      decimals: 18,
    },
  },
  {
    token1: 'GHE',
    decimals: 18,
    addressByChain: {
      '56': '0x9AD44e8Bf6C485dB08b041185a05c0d16D16069c',
    },
    poolAddress: '0xE2Ee4DB99d1731F1fE3e2E56D4B07A2534826Bf9',
    reward: {
      address: '0x9AD44e8Bf6C485dB08b041185a05c0d16D16069c',
      name: 'GHE',
      decimals: 18,
    },
  },

  // {
  //   token1: 'GHC',
  //   token2: 'BNB',
  //   decimals: 18,
  //   addressByChain: {
  //     '56': '0xcFD9ee6D907f62f03942cA2e6f94f40e35d29c2F',
  //     '31337': '0x0000000000000000000000000000000000000000',
  //   },
  //   poolAddress: '0x2E8aB8F7A416BC96D0A7421F3F9eD60632E0005B',
  //   reward: {
  //     address: '0x683fae4411249ca05243dfb919c20920f3f5bfe0',
  //     name: 'GHC',
  //     decimals: 9,
  //   },
  // },
  // {
  //   token1: 'GHC',
  //   decimals: 9,
  //   addressByChain: {
  //     '56': '0x683fae4411249ca05243dfb919c20920f3f5bfe0',
  //     '31337': '0x0000000000000000000000000000000000000000',
  //   },
  //   poolAddress: '0xCC39e29F8aef9F9D7AD0082047d09Fb1446fa3de',
  //   reward: {
  //     address: '0x683fae4411249ca05243dfb919c20920f3f5bfe0',
  //     name: 'GHC',
  //     decimals: 9,
  //   },
  // },
]

export const addressesByChain: any = {
  BNB: {
    '56': '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    '97': '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
  },
}

export const nftStakingFarmByChain: any = {
  56: '0x009f5d6a96ed8a02f5276C101CfC59Ac020265AC',
}