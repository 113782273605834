import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

const pricesSlice = createSlice({
  name: 'prices',
  initialState: {
    bnbUsdt: new BigNumber(0),
    ghcBnb: new BigNumber(0),
    ghcUsdt: new BigNumber(0),
  },
  reducers: {
    setPrices: (state, action) => {
      state.bnbUsdt = action.payload.bnbUsdt
      state.ghcBnb = action.payload.ghcBnb
      state.ghcUsdt = action.payload.ghcBnb.multipliedBy(action.payload.bnbUsdt)
    },
  },
})

export const { setPrices } = pricesSlice.actions
export default pricesSlice.reducer
