import BigNumber from 'bignumber.js'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

export const ERROR_NUMBER_TO_BIGINT_ARG_NOT_STRING =
  'First argument must be a string and must parse to a number'
export const ERROR_NUMBER_TO_BIGINT_ARG_MUST_BE_NUMERIC =
  'First argument must be a string and must parse to a number'
export const ERROR_NUMBER_TO_BIGINT_RESULT_NOT_INTEGER =
  'Exponent smaller than length of decimal. Result will not be an integer.'
