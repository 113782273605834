import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'hooks/useContract'
import { Link } from 'react-router-dom'
import useToast from 'hooks/useToast'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Card } from '@pancakeswap-libs/uikit'
import { Wrapper } from 'components/swap/styleds'
import Container from 'components/Container'
import CardNav from 'components/CardNav'
import NftCard from 'components/NftCard'
import GlowHeading from 'components/GlowHeading'
import { shortenAccount } from 'utils/formatAccount'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import * as NftMarketplace from 'config/abis/NftMarketplace.json'
import { marketAddressByChain } from 'config'
import { nftAddressByChain } from 'config'
import NftPageHeader from 'components/NftPageHeader'
import AuctionCard from 'components/NftCard/AuctionCard'
import { apiUrl } from 'connectors'

export const Heading = styled.div`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.topMargin ? '30px' : '0')};
  color: black;
  filter: drop-shadow(3px 1px 1px white);
`

export const SubHeading = styled(Heading)`
  font-size: 25px;
  margin: 10px;
  ${({ left }) => left && 'text-align: left;'}
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: center;
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  width: 350px;
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(29, 162, 231);
  box-shadow: rgb(0 146 224) 0px 0px 1.5rem,
    rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  overflow: visible;
`

const FlexElement = styled.div`
  padding: 10px;
  flex: 1;
  justify-content: center;
`

export const Btn = styled.button`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: rgb(29, 162, 231);
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: rgba(178, 96, 220, 0.8);
  }
`
const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`
const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`
const PageLabel = styled.span`
  color: white;
  vertical-align: top;
  padding: 0 10px;
`

const PER_PAGE = 15

const NftWallet = ({ match: { path } }) => {
  const { account, chainId } = useActiveWeb3React()
  const nftContract = useContract(
    nftAddressByChain[chainId || '56'],
    GalaxyHeroesNFT.abi,
    true
  )
  const marketContract = useContract(
    marketAddressByChain[chainId || '56'],
    NftMarketplace.abi,
    true
  )
  const [loading, setLoading] = useState(true)
  const [tokens, setTokens] = useState([])
  const [renderKey, setRenderKey] = useState('0')
  const { toastSuccess, toastError } = useToast()
  const [customTokens, setCustomTokens] = useState([])
  const [accountLabel, setAccountLabel] = useState('')
  const [page, setPage] = useState(1)

  const showListings = path === '/nftmylistings'
  const nullMessage = showListings
    ? 'You have no active listings'
    : 'No Galaxy NFTs in your wallet'

  useEffect(() => {
    const loadData = async () => {
      if (account) {
        setLoading(true)
        try {
          setAccountLabel(shortenAccount(account))
          const tokensOwnedCount = await nftContract?.callStatic.balanceOf(
            account
          )
          if (tokensOwnedCount.toString !== '0') {
            const usersTokens = await nftContract?.callStatic.walletOfOwner(
              account
            )
            setTokens(usersTokens)
          }

          setLoading(false)
        } catch (e) {
          console.error('error is', e)

          setLoading(false)
          throw e
        }
      }
    }
    loadData()
  }, [
    account,
    chainId,
    nftContract,
    renderKey,
    path,
    marketContract?.callStatic,
  ])
  useEffect(() => {
    const fetchCustomTokens = async () => {
      try {
        const searchParams = new URLSearchParams()
        searchParams.append('tokenOwnerAddress', account)
        searchParams.append('auctionState', 'closed')
        const response = await fetch(`${apiUrl}/tokens?${searchParams}`)
        const rawCustomTokens = await response.json()
        setCustomTokens(
          rawCustomTokens?.map((entry) => ({
            ...entry,
            tokenId: entry.id,
            tokenContract: entry.tokenAddress,
          }))
        )
      } catch (e) {
        console.error(e)
      }
    }
    fetchCustomTokens()
  }, [account])

  const mergedTokens = [...tokens, ...customTokens]
  const displayedTokens =
    mergedTokens.length &&
    mergedTokens.slice((page - 1) * PER_PAGE, page * PER_PAGE)
  const totalPages = Math.ceil(mergedTokens.length / PER_PAGE)
  return (
    <Container>
      <Heading> Your NFTS</Heading>
      <CardNav />
      <CardWrapper style={{ zIndex: 100 }}>
        <Wrapper id="nft-wallet-page">
          <NftPageHeader
            showBack
            title={accountLabel}
            description={accountLabel && 'Your collection of Galaxy NFTs'}
          />
        </Wrapper>
      </CardWrapper>
      <hr />
      <FlexContainer>
        {account ? (
          loading ? (
            'Loading...'
          ) : mergedTokens.length > 0 ? (
            <>
              {displayedTokens.map((displayedToken) =>
                displayedToken.tokenAddress != null ? (
                  <AuctionCard
                    key={
                      displayedToken.tokenContract +
                      displayedToken.tokenId.toString()
                    }
                    contract={displayedToken.tokenContract}
                    id={Number(displayedToken.tokenId)}
                    title={displayedToken.name}
                    description={displayedToken.description}
                    mediaUri={displayedToken.mediaUri}
                    tokenUri={displayedToken.tokenUri}
                    attributes={displayedToken.attributes}
                    nftStatus={displayedToken.status}
                  />
                ) : (
                  <NftCard
                    key={Number(displayedToken)}
                    contract={nftContract}
                    id={Number(displayedToken)}
                  />
                )
              )}
            </>
          ) : (
            <SubHeading>{nullMessage}</SubHeading>
          )
        ) : (
          <GlowHeading>Connect Your Wallet to see NFTs</GlowHeading>
        )}
      </FlexContainer>
      {mergedTokens.length > PER_PAGE && (
        <div style={{ lineHeight: '25px' }}>
          {page > 1 && (
            <StyledArrowLeft
              onClick={() => {
                setPage(page - 1)
              }}
            />
          )}
          <PageLabel>
            {page} of {totalPages}
          </PageLabel>
          {page < totalPages && (
            <StyledArrowRight
              onClick={() => {
                setPage(page + 1)
              }}
            />
          )}
        </div>
      )}
      <br />
      {showListings ? (
        <Link to="/nftwallet">
          <Btn>Create Listing</Btn>
        </Link>
      ) : (
        <Link to="/nftmarket">
          <Btn>Get NFTs on our Marketplace</Btn>
        </Link>
      )}
    </Container>
  )
}

export default NftWallet
