import React from 'react'
import CardFlex from 'components/CardFlex'

const Attribute = ({ attribute }) => {
  const { attrName, attrValue, attrPct } = attribute
  const name = attrName?.replace(/[^a-zA-Z ]/g, '')
  const value = attrValue?.replace('_', ' ')

  return (
    <div>
      <CardFlex key={attrName}>
        <div>{name}</div>
        <div>
          {value} {attrPct ? ` (${(attrPct * 100).toFixed(1)}%)` : ''}
        </div>
      </CardFlex>
    </div>
  )
}

export default Attribute
