export const fileSizeValidator = (file) =>
  file.size > 8e6
    ? { code: 'file-too-large', message: 'Files must be 8 MB or smaller' }
    : null

export const notEmptyStringValidator = (query) => query !== ''

export const greaterThanZeroValidator = (query) => parseFloat(query) > 0

export const numericValidator = (query) => Number.isFinite(Number(query))

export const noEmptyValuesValidator = (query) =>
  query?.find(
    (item) => Object.values(item).find((value) => value === '') !== undefined
  ) === undefined
