import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useActiveWeb3React } from 'hooks'
import { customNftAddressByChain } from 'config'
import CustomContractDetails from './CustomContractDetails'
import GalaxyHeroesDetails from './GalaxyHeroesDetails'

export default function NftPdp({
  history,
  match: {
    params: { nftCollectionAddress, tokenId },
  },
}: RouteComponentProps<{ nftCollectionAddress: string; tokenId: string }>) {
  const { chainId } = useActiveWeb3React()

  if (nftCollectionAddress === customNftAddressByChain[chainId || '56']) {
    return (
      <CustomContractDetails
        history={history}
        nftCollectionAddress={nftCollectionAddress}
        databaseId={tokenId}
      />
    )
  }
  return (
    <GalaxyHeroesDetails
      history={history}
      nftCollectionAddress={nftCollectionAddress}
      tokenId={tokenId}
    />
  )
}
