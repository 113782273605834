import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useContract } from 'hooks/useContract'
import { Link } from 'react-router-dom'
import Pagination from 'rc-pagination'
import styled from 'styled-components'
import queryString from 'query-string'
import BigNumber from 'bignumber.js'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Card, Button } from '@pancakeswap-libs/uikit'
import Container from 'components/Container'
import CardNav from 'components/CardNav'
import NftCard from 'components/NftCard'
import AuctionCard from 'components/NftCard/AuctionCard'
import GlowHeading from 'components/GlowHeading'
import { marketAddressByChain, nftAddressByChain } from 'config'
import { getNumber } from 'utils/formatBalance'
import * as GalaxyHeroesNFT from 'config/abis/GalaxyHeroesNFT.json'
import * as NftMarketplace from 'config/abis/NftMarketplace.json'
import { shortenAccount } from 'utils/formatAccount'
import { useLocation, useHistory } from 'react-router-dom'
import NftPageHeader from 'components/NftPageHeader'
import { apiUrl } from 'connectors'
import filterAuctions from './utils'
import './pagination.css'

export const Heading = styled.div`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.topMargin ? '30px' : '0')};
  color: black;
  filter: drop-shadow(3px 1px 1px white);
`

export const SubHeading = styled(Heading)`
  font-size: 25px;
  margin: 10px;
  ${({ left }) => left && 'text-align: left;'}
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: center;
`

export const CardWrapper = styled(Card)`
  position: relative;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
  width: 450px;
  max-width: 100%;
  z-index: 5;
  background-color: rgba(1, 1, 1, 0.6);
  border: 2px solid rgb(29, 162, 231);
  box-shadow: rgb(0 146 224) 0px 0px 1.5rem,
    rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;
  margin: auto;
  overflow: visible;
`

export const Btn = styled.button`
  border-width: 0px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  background-color: rgb(29, 162, 231);
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.25s;
  &:hover {
    background-color: rgba(178, 96, 220, 0.8);
  }
`
const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`
const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.colors.text};
  fill: none !important;
  cursor: pointer;
`
const PageLabel = styled.span`
  color: white;
  vertical-align: top;
  padding: 0 10px;
`

const FETCH_LIMIT = 1000
const PER_PAGE = 15

const NftMarket = ({ match: { path } }) => {
  const { account, chainId } = useActiveWeb3React()
  const showUserListings = path === '/nftmylistings' && account

  const { pathname, search } = useLocation()
  const history = useHistory()
  const {
    priceMin,
    priceMax,
    sort,
    query,
    page = 1,
  } = queryString.parse(search)

  const marketAddress = marketAddressByChain[chainId || '56']
  const nftAddress = nftAddressByChain[chainId || '56']
  const marketContract = useContract(marketAddress, NftMarketplace.abi, true)

  const [loading, setLoading] = useState(true)
  const [aucs, setAucs] = useState([])
  const [customTokenActiveAuctions, setCustomTokenActiveAuctions] = useState([])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      try {
        if (showUserListings) {
          const auctionStructs =
            await marketContract?.callStatic.getListedNftsOfUser(account)
          const auctionIds = auctionStructs.map((as) => Number(as.auctionId))
          const auctions = await marketContract?.callStatic.getAuctions(
            auctionIds
          )
          setAucs(auctions)
        } else {
          const { auctions } = await marketContract?.callStatic.fetchAuctions(
            0,
            FETCH_LIMIT
          )
          const auctionsCount =
            await marketContract.callStatic.getAuctionsCount()
          setAucs(auctions)
        }
        setLoading(false)
      } catch (e) {
        console.error('error is', e)
        setLoading(false)
      }
    }
    loadData()
  }, [account, chainId, marketContract, showUserListings])

  useEffect(() => {
    const fetchCustomTokens = async () => {
      try {
        const searchParams = new URLSearchParams()
        if (showUserListings) searchParams.append('tokenOwnerAddress', account)
        searchParams.append('auctionState', 'open')
        if (query) searchParams.append('search', query)
        const tokenFetch = fetch(`${apiUrl}/tokens?${searchParams}`)

        const tokenResponse = await tokenFetch
        const tokens = await tokenResponse.json()

        setCustomTokenActiveAuctions(
          tokens?.map((entry) => ({
            ...entry,
            tokenId: entry.id,
            reservePrice: new BigNumber(entry.reservePrice),
            buyNowPrice: new BigNumber(entry.buyNowPrice),
            firstBidTime: entry.firstBidTime
              ? new BigNumber(new Date(entry.firstBidTime)?.getTime() / 1000)
              : new BigNumber(0),
            amount: new BigNumber(entry.bidAmount || 0),
            duration: Number(entry.duration || 1),
            tokenContract: entry.tokenAddress,
          }))
        )
      } catch (e) {
        console.error(e)
      }
    }
    fetchCustomTokens()
  }, [account, showUserListings, query])

  const galaxyHeroAuctions = aucs.filter(
    (auction) => auction.tokenContract === nftAddress
  )

  const mergedAuctions = [...galaxyHeroAuctions, ...customTokenActiveAuctions]
  const filteredAuctions = filterAuctions(mergedAuctions, {
    priceMin,
    priceMax,
    sort,
  })
  const aucsToDisplay = filteredAuctions.slice(
    (page - 1) * PER_PAGE,
    page * PER_PAGE
  )
  const totalPages = Math.ceil(filteredAuctions.length / PER_PAGE)

  const onChangePage = (newPage) => {
    const searchQuery = queryString.stringify({
      page: newPage,
      priceMin,
      priceMax,
      sort,
    })
    history.replace({
      search: searchQuery,
    })
  }
  const clearFilters = () => {
    history.replace({
      search: '',
    })
  }
  return (
    <Container>
      <Heading>
        {showUserListings ? 'Your Listings' : 'Live NFTs for sale'}
      </Heading>
      <CardNav />
      <CardWrapper style={{ zIndex: 100 }}>
        <NftPageHeader
          title="Marketplace"
          description={
            showUserListings ? 'Your Live Auctions' : 'Public Live Auctions'
          }
        />
      </CardWrapper>
      <hr />
      <FlexContainer>
        {loading ? (
          'Loading...'
        ) : aucsToDisplay.length > 0 ? (
          <>
            {aucsToDisplay.map((auction) => {
              const {
                tokenContract,
                reservePrice,
                amount,
                tokenId,
                firstBidTime,
                duration,
              } = auction
              const endTime =
                new Date(firstBidTime * 1000).getTime() +
                Number(duration) * 1000
              const floorPrice = Math.max(
                getNumber(reservePrice),
                getNumber(amount)
              )
              return tokenContract === nftAddress ? (
                <NftCard
                  key={tokenContract + tokenId.toString()}
                  contract={tokenContract}
                  id={Number(tokenId)}
                  endTime={Number(firstBidTime) && new Date(endTime)}
                  price={floorPrice}
                />
              ) : (
                <AuctionCard
                  key={auction.tokenContract + auction.tokenId.toString()}
                  contract={auction.tokenContract}
                  id={Number(auction.tokenId)}
                  title={auction.name}
                  description={auction.description}
                  mediaUri={auction.mediaUri}
                  tokenUri={auction.tokenUri}
                  attributes={auction.attributes}
                  endTime={Number(auction.firstBidTime) && new Date(endTime)}
                  price={floorPrice}
                />
              )
            })}
          </>
        ) : (
          <SubHeading>
            {showUserListings ? (
              'You have no active listings'
            ) : (
              <div>No Auctions Found</div>
            )}
          </SubHeading>
        )}
      </FlexContainer>
      {(priceMin || priceMax || sort || page) && (
        <Button variant="text" onClick={clearFilters}>
          Not seeing results? Try clearing filters.
        </Button>
      )}
      {totalPages > 1 && (
        <div style={{ lineHeight: '25px' }}>
          <Pagination
            className="ant-pagination"
            pageSize={15}
            current={page}
            total={filteredAuctions.length}
            onChange={onChangePage}
          />
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
      <Link to="/nftwallet">
        <Btn>Create Listing</Btn>
      </Link>
    </Container>
  )
}

export default NftMarket
