import React, { useCallback } from 'react'
import { useActiveWeb3React } from 'hooks'
import { useSelector, useDispatch } from 'react-redux'
import { useWalletModal } from '@pancakeswap-libs/uikit'
import useAuth from 'hooks/useAuth'
import { BottomGrouping, Wrapper } from 'components/swap/styleds'
import { CardBody, Text, Button } from '@pancakeswap-libs/uikit'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import useToast from 'hooks/useToast'
import Dropzone from 'components/Dropzone'
import InputLayout from 'components/InputLayout'
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import AttributeTable from 'components/AttributeTable'
import NftPageHeader from 'components/NftPageHeader'
import { mintCustomNft as mintNft } from 'utils/callHelpers'
import {
  fileSizeValidator,
  notEmptyStringValidator,
  noEmptyValuesValidator,
} from 'utils/dataValidators'
import {
  setName,
  setDescription,
  setAttributesList,
  setAsset,
  setStatus,
  clearCustomNftState,
} from '../../state/customNfts/mint'
import AppBody from '../../pages/AppBody'
import { AppState } from '../../state'
import { ASSET_STORAGE_RATE } from '../../constants'

const validateName = (query) => notEmptyStringValidator(query)
const validateDescription = (query) => notEmptyStringValidator(query)
const validateAttributesList = (query) => noEmptyValuesValidator(query)

const CustomNftMint = () => {
  const { account } = useActiveWeb3React()
  const { toastSuccess, toastError } = useToast()
  const { login, logout } = useAuth()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account || undefined
  )
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    name: nftName,
    description,
    attributesList,
    asset,
    storageFee,
    status: mintStatus,
  } = useSelector((state: AppState) => state.customNfts.mint)

  const onNameChanged = useCallback(
    (event) => dispatch(setName(event.target.value)),
    [dispatch]
  )
  const onDescriptionChanged = useCallback(
    (event) => dispatch(setDescription(event.target.value)),
    [dispatch]
  )
  const onAttributesListChanged = useCallback(
    (newList) => dispatch(setAttributesList(newList)),
    [dispatch]
  )

  const isError = useCallback(() => mintStatus === 'error', [mintStatus])

  const validateInputs = useCallback(
    () =>
      validateName(nftName) &&
      validateDescription(description) &&
      validateAttributesList(attributesList),
    [nftName, description, attributesList]
  )

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toastError(fileRejections[0].errors[0].message)
      } else {
        const { name, size, type } = acceptedFiles[0]
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          dispatch(setAsset({ name, size, type, url: reader.result }))
        })
        reader.readAsDataURL(acceptedFiles[0])
      }
    },
    [dispatch, toastError]
  )

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: 'image/jpeg,image/png,image/gif,video/mp4',
      maxFiles: 1,
      validator: fileSizeValidator,
      onDrop,
    })

  const sendMintRequest = useCallback(async () => {
    try {
      const res = await mintNft(
        nftName,
        description,
        attributesList,
        acceptedFiles[0],
        storageFee,
        account
      )

      if (res.ok) {
        const data = await res.json()

        dispatch(setStatus('success'))
        dispatch(clearCustomNftState())
        history.push(`/nftpdp/${data.tokenAddress}/${data.id}`)
      } else {
        dispatch(setStatus('failure'))
        if (res.status >= 500) {
          toastError(
            'There was an issue processing your request. Please try again later.'
          )
        } else if (res.status === 422) {
          toastError(
            'The NFT you are trying to create is invalid. Please update your NFT and try again.'
          )
        } else if (res.status === 404) {
          toastError('The server could not be reached. Please try again.')
        } else {
          toastError('Unexpected error. Please try again.')
        }
      }
    } catch (error) {
      dispatch(setStatus('failure'))
      console.error(error)
      toastError('Unexpected error. Please try again.')
    }
  }, [
    dispatch,
    acceptedFiles,
    nftName,
    description,
    attributesList,
    toastError,
    storageFee,
    account,
    history,
  ])

  const mintCustomNft = useCallback(() => {
    if (!validateInputs()) {
      dispatch(setStatus('error'))
      toastError('Could not create NFT, missing required information.')
    } else {
      dispatch(setStatus('pending'))
      sendMintRequest()
    }
  }, [dispatch, validateInputs, sendMintRequest, toastError])

  return (
    <AppBody>
      <Wrapper id="swap-page">
        <NftPageHeader title="Mint" description="Create a custom NFT" />
        <CardBody style={{ textAlign: 'center' }}>
          <Dropzone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            isDragActive={isDragActive}
            preview={asset}
            isWarning={isError() && !asset}
          />
          {storageFee ? (
            <Text>Storage fee is ${`${storageFee}`} (USD)</Text>
          ) : (
            <Text>File storage is ${`${ASSET_STORAGE_RATE}`}/MB (USD)</Text>
          )}
          <InputLayout title="Title">
            <Input
              isWarning={isError() && !validateName(nftName)}
              placeholder={
                isError() && !validateName(nftName)
                  ? 'A title is required'
                  : undefined
              }
              value={nftName}
              onChange={onNameChanged}
            />
          </InputLayout>
          <InputLayout title="Description">
            <Textarea
              value={description}
              isWarning={isError() && !validateDescription(description)}
              placeholder={
                isError() && !validateDescription(description)
                  ? 'A description is required'
                  : undefined
              }
              onChange={onDescriptionChanged}
            />
          </InputLayout>
          <InputLayout title="Attributes (optional)">
            <AttributeTable
              showWarnings={isError()}
              attributesList={attributesList}
              keyHeader="CATEGORY"
              valueHeader="VALUE"
              onAttributesListChanged={onAttributesListChanged}
            />
          </InputLayout>
          <BottomGrouping>
            {account ? (
              <Button
                onClick={mintCustomNft}
                disabled={mintStatus === 'pending'}
              >
                {mintStatus === 'pending' ? 'Pending...' : 'CREATE'}
              </Button>
            ) : (
              <Button onClick={onPresentConnectModal}>
                Connect Wallet To Mint
              </Button>
            )}
          </BottomGrouping>
        </CardBody>
      </Wrapper>
    </AppBody>
  )
}

export default CustomNftMint
