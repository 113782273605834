import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { Flex, Text } from '@pancakeswap-libs/uikit'
import { ASSET_STORAGE_RATE } from '../../constants'

const Dropzone = styled(
  ({
    className,
    getRootProps,
    getInputProps,
    isDragActive,
    preview,
    isWarning,
  }) => (
    <div className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Flex alignItems="center" flexDirection="column">
          <FontAwesomeIcon icon={faImage} size="10x" />
          <Text>Drop the file here ...</Text>
        </Flex>
      ) : (
        <Flex alignItems="center" flexDirection="column">
          {preview?.type.startsWith('image') ? (
            <img alt="NFT preview" src={preview.url} />
          ) : preview?.type.startsWith('video') ? (
            <video controls style={{ maxWidth: '100%' }}>
              <source src={preview.url} type={preview.type} />
            </video>
          ) : (
            <>
              <FontAwesomeIcon icon={faImage} size="10x" />
              <Text>PNG | GIF | JPG | MP4 (8 MB max)</Text>
            </>
          )}
        </Flex>
      )}
    </div>
  )
)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  flex-grow: 1;
  outline: none;
  user-select: none;
  ${({ isDragActive, preview, theme }) =>
    isDragActive || preview
      ? `
  color: white;
  border: 5px solid white;
  opacity: 1;
  `
      : `
  border: 5px solid white;
  opacity: 0.5;
  `};
  & * {
    border-radius: 14px;
    max-width: 100%;
  }
  ${({ isWarning, theme }) =>
    isWarning &&
    `
  border: 5px solid ${theme.colors.failure};
  box-shadow: 0px 0px 0px 1px ${theme.colors.failure} ,0px 0px 0px 4px ${theme.colors.failure}33;
  `}
};
`

export default Dropzone
