import React, { useState, useMemo, useEffect } from 'react'
import { useActiveWeb3React } from 'hooks'
import { Link } from 'react-router-dom'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { usePairs } from 'data/Reserves'
import { Button } from '@pancakeswap-libs/uikit'
import GetPrice from 'utils/getPrice'

import { FarmsContainer, StakeContainer, StakeHeading, Label } from './styleds'
import { farmData } from './data'
import Farm from './farm'
import NftFarm from './nftFarm'

const Farms = () => {
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toV2LiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] =
    useTokenBalancesWithLoadingIndicator(account ?? undefined, liquidityTokens)

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(
    liquidityTokensWithBalances.map(({ tokens }) => tokens)
  )
  const allV2PairsWithLiquidity = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair) => Boolean(v2Pair))

  const [rewardTokenPrice, setrewardTokenPrice] = useState('0')
  const [gheTokenPrice, setGheTokenPrice] = useState('0')
  const [bnbPrice, setBnbPrice] = useState('0')

  useEffect(() => {
    async function getTokenPrices() {
      const rewardTokenClass = new GetPrice()
      await rewardTokenClass.init('GHC')
      const {
        pairPriceA: esbbPrice,
        pairPriceB: bbprice,
        price: esprice,
      } = await rewardTokenClass.getLatestPrice()
      const gheTokenClass = new GetPrice()
      await gheTokenClass.init('GHE')
      const { pairPriceA: gheUsdtPrice } = await gheTokenClass.getLatestPrice()
      setrewardTokenPrice(esprice)
      setGheTokenPrice(gheUsdtPrice)
      setBnbPrice(bbprice)
    }
    getTokenPrices()
  }, [])

  return (
    <FarmsContainer>
      <StakeContainer>
        <StakeHeading topMargin>GALACTIC FARMS V6.1</StakeHeading>
        {!account && (
          <>
            <br />
            <p>Connect your wallet to see pools!</p>
            <br />
          </>
        )}
        {farmData.map((farm, i) => {
          const rewardPrice = i < 3 ? gheTokenPrice : rewardTokenPrice
          return (
            <Farm
              key={farm.poolAddress}
              farmData={farm}
              rewardTokenPrice={rewardPrice}
              bnbPrice={bnbPrice}
              ghcPrice={rewardTokenPrice}
              ghePrice={gheTokenPrice}
            />
          )
        })}
        <NftFarm bnbPrice={bnbPrice} />
        {/* <Label> Looking for v1 or can&apos;t find your staked balances?</Label>
        <br />
        <Link to="/farmsv1">
          <Button>Go to Farms V1</Button>
        </Link> */}
      </StakeContainer>
    </FarmsContainer>
  )
}

export default Farms
