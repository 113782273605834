/* eslint-disable */
/* eslint no-use-before-define: 0 */ // --> OFF
// A eseed library for common on-chain data. Currently just price data.
// If you steal this, at least give me some credit -- credit to : https://github.com/john--

const PANCAKESWAP_FACTORY_ADDR_V2 = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'
const ADDRESS_BNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
const ADDRESS_GHC = '0x22dE16487955AE1f842fa0C0bFf6083a7fDEfeDd'
const ADDRESS_GHE = '0x9AD44e8Bf6C485dB08b041185a05c0d16D16069c'
const ADDRESS_USDT = '0x55d398326f99059ff775485246999027b3197955'
const ENABLE_PANCAKESWAP_V2 = true
const BigNumber = require('bignumber.js')
const {
  tokenToString,
  collapseTextChangeRangesAcrossMultipleVersions,
} = require('typescript')
const Web3 = require('web3')
const web3 = new Web3(process.env.REACT_APP_NETWORK_URL)
const pancakeswapFactoryAbi = require('config/abis/PancakeFactory.json')
const pancakeswapPairAbi = require('config/abis/PancakePair.json')

class GetPrice {
  pancakeswapFactoryV2 = new web3.eth.Contract(
    pancakeswapFactoryAbi.abi,
    PANCAKESWAP_FACTORY_ADDR_V2
  )

  constructor() {}

  init = async function (token) {
    this.tokenAddress = ADDRESS_GHC
    this.eseedDecimals = 18
    this.contractPairsA = []
    this.contractPairsB = []

    if (ENABLE_PANCAKESWAP_V2) {
      // PCS V2, pairA = REWARD/BNB
      let contractPairA
      if (token === 'GHC') {
        contractPairA = await this.getContractPair(
          this.pancakeswapFactoryV2,
          ADDRESS_BNB,
          this.tokenAddress
        )
      } else if (token === 'GHE') {
        this.tokenAddress = ADDRESS_GHE
        this.eseedDecimals = 18
        contractPairA = await this.getContractPair(
          this.pancakeswapFactoryV2,
          this.tokenAddress,
          ADDRESS_USDT
        )
      }
      

      this.contractPairsA.push(contractPairA)

      // PCS V2, pairB = BNB/USDT
      const contractPairB = await this.getContractPair(
        this.pancakeswapFactoryV2,
        ADDRESS_BNB,
        ADDRESS_USDT
      )

      this.contractPairsB.push(contractPairB)
    }
  }

  getContractPair = async function (factory, address0, address1) {
    const pairAddress = await factory.methods.getPair(address0, address1).call()

    const contract = new web3.eth.Contract(pancakeswapPairAbi.abi, pairAddress)
    const token0 = await contract.methods.token0().call()
    contract.addressOrderReversed =
      token0.toLowerCase() !== address0.toLowerCase()
    if (address1 === ADDRESS_GHC) {
      contract.addressOrderReversed = false
    }
    return contract
  }

  // Price is reserve1/reserve0. However, sometimes we want to take the average of all of the pairs in the
  // event there are multiple liquidity pools. This helps in those cases.
  getAveragedPriceFromReserves = function (callContractAndResultList) {
    const reserve0 = callContractAndResultList.reduce(
      (a, b) =>
        a.plus(
          new BigNumber(b.result[b.contract.addressOrderReversed ? '1' : '0'])
        ),
      new BigNumber(0)
    )
    const reserve1 = callContractAndResultList.reduce(
      (a, b) =>
        a.plus(
          new BigNumber(b.result[b.contract.addressOrderReversed ? '0' : '1'])
        ),
      new BigNumber(0)
    )
    return reserve1.dividedBy(reserve0)
  }

  // web3.eth.BatchRequest allows us to batch requests, but each of the requests
  // have their own callback and return individually. It makes it a little hard to manage like this.
  // This is just a Promise that returns the entire result once they've all completed.
  batchCalls = function (callAndContractList) {
    return new Promise((resolve, reject) => {
      let operations = callAndContractList.map((c) => ({
        call: c.call,
        contract: c.contract,
        completed: false,
        result: null,
      }))

      const callback = function (callAndContract, error, response) {
        if (error) {
          reject(error)
        }

        const currentOperation = operations.find(
          (c) => c.call === callAndContract.call
        )
        currentOperation.completed = true
        currentOperation.result = response

        if (operations.every((o) => o.completed)) {
          resolve(operations)
        }
      }

      let batch = new web3.eth.BatchRequest()
      callAndContractList.forEach((cc) => {
        batch.add(cc.call.call.request((e, r) => callback(cc, e, r)))
      })

      batch.execute()
    })
  }

  getLatestPrice = async function () {
    const reservesResultsA = await this.batchCalls(
      this.contractPairsA.map((cp) => ({
        call: cp.methods.getReserves(),
        contract: cp,
      }))
    )

    const reservesResultsB = await this.batchCalls(
      this.contractPairsB.map((cp) => ({
        call: cp.methods.getReserves(),
        contract: cp,
      }))
    )

    // Calculate average price for rewardToken/BNB pair from reserves for PCS V1/V2
    const pairPriceA = this.getAveragedPriceFromReserves(reservesResultsA)

    // Calculate average price for BNB/USDT pair from reserves for PCS V1/V2
    const pairPriceB = this.getAveragedPriceFromReserves(reservesResultsB)

    // Multiply pair A by pair B to get the USD value
    let price = pairPriceA.multipliedBy(pairPriceB)
    if (this.tokenAddress === ADDRESS_GHC) {
      price = price.dividedBy(Math.pow(10, 18 - this.eseedDecimals))
    }

    // number is still a whole number, apply the proper decimal places from the contract (18)
    return { price, pairPriceA, pairPriceB }
  }
}

export default GetPrice
