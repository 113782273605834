import styled from 'styled-components'

const GlowHeading = styled.div`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  margin-top: 30px;
  color: black;
  filter: drop-shadow(3px 1px 1px white);
` 
export default GlowHeading