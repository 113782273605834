import React, { useState, useEffect } from 'react'
import { BigNumber, formatFixed } from '@ethersproject/bignumber'
import BigNumberJS from 'bignumber.js'
import Web3 from 'web3'
import { ethers } from 'ethers'
import useToast from 'hooks/useToast'
import { useContract, useTokenContract } from 'hooks/useContract'
import useAuth from 'hooks/useAuth'
import { useModal, useWalletModal } from '@pancakeswap-libs/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { getBalanceString, getDecimalAmount } from 'utils/formatBalance'
import { MouseoverTooltip } from 'components/Tooltip'
import { nftStakingFarmByChain } from 'pages/Farms/data'
import { nftAddressByChain } from 'config/index'
import { useActiveWeb3React } from '../../hooks'
import * as FarmContractV2 from '../../config/abis/HighstackNftFarmCerts.json'

import {
  FormInfo,
  StackDataContainer,
  SmallStackDataContainer,
  Label,
  BigLabel,
  Value,
  ColoredValue,
  Details,
  BlackChevronDown,
  BlackChevronUp,
  LogoContainer,
  FirstLogo,
  SecondLogo,
  FormContainer,
  DetailsContainer,
  FarmsDetails,
  FarmDetailsData,
  FarmDetailsActions,
  FarmDetailsRow,
  FormActionButton,
} from './styleds'
import { addressesByChain } from './data'
import DepositNftModal from './DepositNftModal'

const Farm = (props) => {
  /* * STATE * */
  const { bnbPrice } = props
  const [showMore, setShowMore] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { toastSuccess, toastError } = useToast()

  const { account, chainId } = useActiveWeb3React()

  const { login, logout } = useAuth()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account || undefined
  )

  const [lpTokenBalance, setLpTokenBalance] = useState('0')
  const [stakingBalance, setStakingBalance] = useState('0')
  const [rewardTokenEarned, setRewardTokenEarned] = useState('0')
  const [totalStaked, setTotalStaked] = useState('0')
  const [totalPaidOut, setTotalPaidOut] = useState('0')
  const [renderKey, setRenderKey] = useState('0')
  const [harvestLoading, setHarvestLoading] = useState(false)

  const farmAddress =
    nftStakingFarmByChain[chainId] || nftStakingFarmByChain['56']
  const lpAddress = nftAddressByChain[chainId] || nftAddressByChain['56']

  const farmContract = useContract(farmAddress, FarmContractV2.abi, true)
  const lpTokenContract = useTokenContract(lpAddress, true)
  const bnbContract = useTokenContract(addressesByChain.BNB[chainId], true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRenderKey(`${Math.random()}`)
    }, 50000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    async function loadData() {
      if (account) {
        // Load User staking balance
        try {
          const userData = await farmContract?.callStatic.balanceOf(account)
          setStakingBalance(userData || '0')
        } catch (e) {
          console.error('failed at setStakingBalance', e)
        }
        // Load User RewardToken Earned
        try {
          const pendingBnb = await farmContract?.callStatic.calcHarvestTot(
            account
          )
          setRewardTokenEarned(pendingBnb || '0')
        } catch (e) {
          console.error('failed at setRewardTokenEarned', e)
          setRewardTokenEarned('-1')
        }

        try {
          const lpTokenBalanceData =
            await lpTokenContract?.callStatic.balanceOf(account)
          setLpTokenBalance(lpTokenBalanceData)
        } catch (e) {
          console.error('failed at setLpTokenBalance', e)
        }
      }
      // check for total staked in farm.
      let stakedInFarm
      try {
        stakedInFarm = await lpTokenContract?.callStatic.balanceOf(farmAddress)
        setTotalStaked(stakedInFarm)
      } catch (e) {
        console.error('e is', e)
      }

      // Calculate totalPaidOut
      try {
        const farmInfo = await farmContract?.callStatic.pool()
        setTotalPaidOut(formatFixed(farmInfo.cumulativeRewards, 18))
      } catch (e) {
        console.error('couldnt get farminfo', e)
      }
      // GET USD released per year per lp token
    }

    loadData()
  }, [
    account,
    renderKey,
    chainId,
    bnbPrice,
    bnbContract?.callStatic,
    farmAddress,
    farmContract,
    lpAddress,
    lpTokenContract,
  ])

  const onDismiss = async () => {
    setShowModal(false)
  }

  const HandleEnable = async () => {
    const maxUint256 = ethers.constants.MaxUint256
    try {
      const spender = farmContract.address
      const isEnabled = await lpTokenContract.approve(spender, maxUint256)
      await isEnabled.wait(1)
      toastSuccess('Contract Enabled', 'You can now stake in the pool')
    } catch (e) {
      if (!account) toastError('Error', 'Please connect your wallet first!')
      else
        toastError(
          'Error',
          'Please try again. Confirm the transaction and make sure you are paying enough gas!'
        )
      console.error('Enable error', e)
    }
    setRenderKey(Math.random())
  }

  const handleHarvest = async () => {
    const message = `Harvested! Your earnings have been sent to your wallet!`
    setHarvestLoading(true)
    try {
      const operation = await farmContract.stakeTokens([])
      await operation.wait(1)
      toastSuccess(message)
      setRenderKey(Math.random())
      onDismiss()
      setHarvestLoading(false)
    } catch (e) {
      console.error(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
      setHarvestLoading(false)
    }
  }

  const handleWithdraw = async () => {
    const message = `Withdrawn! All your staked NFTs have been sent back to your wallet!`
    setHarvestLoading(true)
    try {
      const operation = await farmContract.unstakeTokens(true)
      await operation.wait(1)
      toastSuccess(message)
      setRenderKey(Math.random())
      onDismiss()
      setHarvestLoading(false)
    } catch (e) {
      console.error(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
      setHarvestLoading(false)
    }
  }

  const HandleDeposit = async (tokenId) => {
    try {
      setHarvestLoading(true)
      const operation = await farmContract.stakeTokens([Number(tokenId)])
      await operation.wait(1)
      toastSuccess(`Successfully Staked #${Number(tokenId)}`)
      setRenderKey(Math.random())
      onDismiss()
      setHarvestLoading(false)
    } catch (e) {
      console.error(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
      setHarvestLoading(false)
    }
  }

  const tokenLabel = 'GXYHRS'
  const [onDeposit] = useModal(
    <DepositNftModal
      max={new BigNumberJS(formatFixed(lpTokenBalance, 0))
        .multipliedBy(99)
        .dividedBy(100)}
      title={`Stake ${tokenLabel}`}
      onDismiss={onDismiss}
      onConfirm={HandleDeposit}
      tokenName={tokenLabel}
      addLiquidityUrl="#/nftmarket?sort=timeEndAsc"
    />
  )

  let rewardTokenStatus

  if (rewardTokenEarned === '0') {
    rewardTokenStatus = 'loading'
  } else if (typeof rewardTokenEarned === 'object') {
    rewardTokenStatus = 'loaded'
  } else if (rewardTokenEarned === '-1') {
    rewardTokenStatus = 'error'
  }
  return (
    <FormContainer inactive={!showMore}>
      <FormInfo>
        <LogoContainer>
          <FirstLogo
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo-circle.png`}
          />
        </LogoContainer>
        <StackDataContainer>
          <BigLabel>Stake {tokenLabel}</BigLabel>
          <ColoredValue>Earn BNB</ColoredValue>
        </StackDataContainer>
        <StackDataContainer>
          <Label>BNB Earned</Label>
          <ColoredValue>
            {rewardTokenStatus === 'loading' && 'Loading...'}
            {rewardTokenStatus === 'loaded' &&
              Number(formatFixed(rewardTokenEarned, 18)).toFixed(4)}
            {rewardTokenStatus === 'error' && (
              <QuestionHelper text="Error loading - try refreshing or if the problem persists, let us know in our telegram channel." />
            )}
          </ColoredValue>
        </StackDataContainer>
        <StackDataContainer>
          <Label>Total Earnings</Label>
          <Value>{totalPaidOut} BNB</Value>
        </StackDataContainer>
        <StackDataContainer>
          <Label>Total Staked</Label>
          <Value>{`${totalStaked} NFTs`}</Value>
        </StackDataContainer>
        <SmallStackDataContainer onClick={() => setShowMore(!showMore)}>
          <Details>Details</Details>

          {showMore ? <BlackChevronUp /> : <BlackChevronDown />}
        </SmallStackDataContainer>
      </FormInfo>
      {showMore && !showModal && (
        <DetailsContainer>
          <FarmsDetails>
            <FarmDetailsData>
              <FarmDetailsRow>BNB Earned:</FarmDetailsRow>
              <FarmDetailsRow colored>
                {rewardTokenStatus === 'error' && (
                  <QuestionHelper text="Error loading - try refreshing or if the problem persists, let us know in our telegram channel." />
                )}
                {rewardTokenStatus === 'loaded' &&
                  Number(formatFixed(rewardTokenEarned, 18)).toFixed(4)}
              </FarmDetailsRow>
              <FarmDetailsRow small>
                {rewardTokenStatus === 'loaded' &&
                  `${Number(
                    Number(formatFixed(rewardTokenEarned, 18)) * bnbPrice
                  ).toFixed(2)}~ USD`}
              </FarmDetailsRow>
            </FarmDetailsData>
            <FarmDetailsActions>
              <FormActionButton
                inactive={harvestLoading}
                onClick={() => {
                  handleHarvest()
                }}
              >
                {harvestLoading ? 'Pending...' : 'Harvest'}
              </FormActionButton>
            </FarmDetailsActions>
          </FarmsDetails>
          <FarmsDetails>
            <FarmDetailsData>
              <FarmDetailsRow small>{tokenLabel} Staked</FarmDetailsRow>
              <FarmDetailsRow>
                {Number(formatFixed(stakingBalance)).toFixed(0)}
              </FarmDetailsRow>
            </FarmDetailsData>
            {/* eslint-disable-next-line */}
            {false ? ( // formerly !farmEnabled
              <FarmDetailsActions>
                <FormActionButton onClick={HandleEnable}>
                  {
                    // eslint-disable-next-line
                    false ? 'Pending' : 'Enable' // formerly FarmEnabledPending
                  }
                </FormActionButton>
              </FarmDetailsActions>
            ) : (
              <FarmDetailsActions>
                {account ? (
                  <>
                    <FormActionButton
                      small
                      inactive={harvestLoading}
                      onClick={onDeposit}
                    >
                      +
                    </FormActionButton>

                    <FormActionButton
                      small
                      inactive={harvestLoading}
                      onClick={handleWithdraw}
                    >
                      -
                    </FormActionButton>
                  </>
                ) : (
                  <FormActionButton onClick={onPresentConnectModal}>
                    Connect
                  </FormActionButton>
                )}
              </FarmDetailsActions>
            )}
          </FarmsDetails>
        </DetailsContainer>
      )}
    </FormContainer>
  )
}

export default Farm
