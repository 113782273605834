import styled from 'styled-components'

interface TextareaProps {
  isWarning?: boolean
}

const Textarea = styled.textarea<TextareaProps>`
  box-shadow: none;
  width: 100%;
  border: none;
  resize: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text};
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 8em;
  padding: 8px 16px;
  outline: none;
  ${({ isWarning, theme }) =>
    isWarning &&
    `
  box-shadow: 0px 0px 0px 1px #ED4B9E,0px 0px 0px 4px rgba(237,75,158,0.2);
  ::placeholder {
    color: ${theme.colors.failure}
  };
  `}
`

export default Textarea
