import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal, LinkExternal } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { MouseoverTooltip } from 'components/Tooltip'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface DepositModalProps {
  max: BigNumber
  onConfirm: (amount: string) => void
  onDismiss: () => void
  onEmergencyWithdraw?: () => void
  tokenName?: string
  addLiquidityUrl?: string
  title?: string
  decimals?: number
}

const CustomButton = styled(Button)`
  background-color: ${(props) => (props.variant === 'secondary' ? 'white' : 'rgb(29, 162, 231)')};
  color: ${(props) => (props.variant === 'secondary' ? 'rgb(29, 162, 231)' : 'white')};
  border: ${(props) => (props.variant === 'secondary' ? '1px solid rgb(29, 162, 231)' : 'none')};
`
const LittleButton = styled(CustomButton)`
  background-color: rgb(173, 57, 67);
  height: 30px;
  font-size: 12px;
`
const CustomLinkExternal = styled(LinkExternal)`
  color: rgba(178, 96, 220, 1);
`

const DepositWithdrawModal: React.FC<DepositModalProps> = ({
  max,
  onConfirm,
  onDismiss,
  onEmergencyWithdraw,
  tokenName = '',
  title = '',
  addLiquidityUrl,
  decimals = 18,
}) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max, decimals)
  }, [max, decimals])

  const valNumber = new BigNumber(val)
  const fullBalanceNumber = new BigNumber(fullBalance)

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (e.currentTarget.validity.valid) {
        setVal(e.currentTarget.value.replace(/,/g, '.'))
      }
    },
    [setVal]
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])
  return (
    <Modal title={title} onDismiss={onDismiss}>
      <ModalInput
        value={val}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalance}
        symbol={tokenName}
        addLiquidityUrl={addLiquidityUrl}
        inputTitle="Stake"
        decimals={decimals}
      />
      <ModalActions>
        <CustomButton variant="secondary" onClick={onDismiss} width="100%" disabled={pendingTx}>
          Cancel
        </CustomButton>
        <CustomButton
          width="100%"
          disabled={pendingTx || !valNumber.isFinite() || valNumber.eq(0) || valNumber.gt(fullBalanceNumber)}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(val)
            setPendingTx(false)
            onDismiss()
          }}
        >
          {pendingTx ? 'Pending Confirmation' : 'Confirm'}
        </CustomButton>
      </ModalActions>
      {onEmergencyWithdraw && (
        <span style={{ margin: 'auto', paddingBottom: 10 }}>
          <MouseoverTooltip text="This will instantly unstake all your staked tokens but forfeit your unharvested rewards. We recommend messaging the admins in Telegram before performing this function">
            <LittleButton
              style={{ alignSelf: 'center' }}
              disabled={pendingTx}
              onClick={async () => {
                setPendingTx(true)
                await onEmergencyWithdraw()
                setPendingTx(false)
                onDismiss()
              }}
            >
              Force Unstake
            </LittleButton>
          </MouseoverTooltip>
        </span>
      )}
      <CustomLinkExternal href={addLiquidityUrl} style={{ alignSelf: 'center' }}>
        Get {tokenName}
      </CustomLinkExternal>
    </Modal>
  )
}

export default DepositWithdrawModal
