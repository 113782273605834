import React, { useState, useEffect } from 'react'
import { BigNumber, formatFixed } from '@ethersproject/bignumber'
import BigNumberJS from 'bignumber.js'
import Web3 from 'web3'
import { ethers } from 'ethers'
import useToast from 'hooks/useToast'
import { useContract, useTokenContract } from 'hooks/useContract'
import { useModal } from '@pancakeswap-libs/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { getBalanceString, getDecimalAmount } from 'utils/formatBalance'
import { MouseoverTooltip } from 'components/Tooltip'

import { useActiveWeb3React } from '../../hooks'
import * as FarmContractV2 from '../../config/abis/FarmV2.json'

import {
  FormInfo,
  StackDataContainer,
  SmallStackDataContainer,
  Label,
  BigLabel,
  Value,
  ColoredValue,
  Details,
  BlackChevronDown,
  BlackChevronUp,
  LogoContainer,
  FirstLogo,
  SecondLogo,
  FormContainer,
  DetailsContainer,
  FarmsDetails,
  FarmDetailsData,
  FarmDetailsActions,
  FarmDetailsRow,
  FormActionButton,
} from './styleds'
import { addressesByChain } from './data'
import DepositWithdrawModal from './DepositWithdrawModal'

const Farm = (props) => {
  /* * STATE * */
  const { farmData, rewardTokenPrice, bnbPrice, ghcPrice, ghePrice } = props
  const { tokenOverride, token1, token2, reward, decimals } = farmData
  const [showMore, setShowMore] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { toastSuccess, toastError } = useToast()

  const { account, chainId } = useActiveWeb3React()

  const [farmEnabled, setFarmEnabled] = useState(false)
  const [farmEnabledPending, setFarmEnabledPending] = useState(false)

  const [lpTokenBalance, setLpTokenBalance] = useState('0')
  const [stakingBalance, setStakingBalance] = useState('0')
  const [rewardTokenEarned, setRewardTokenEarned] = useState('0')
  const [totalStaked, setTotalStaked] = useState('0')
  const [apr, setApr] = useState('0')
  const [renderKey, setRenderKey] = useState('0')
  const [harvestLoading, setHarvestLoading] = useState(false)

  const farmAddress = farmData.poolAddress
  const lpAddress =
    farmData.addressByChain[chainId] || farmData.addressByChain['56']
  const farmContract = useContract(farmAddress, FarmContractV2.abi, true)
  const lpTokenContract = useTokenContract(lpAddress, true)
  const bnbContract = useTokenContract(addressesByChain.BNB[chainId], true)

  /* ** CONSTANTS ** */
  // Production
  let inactive = false
  if (reward.name === 'GHC') {
    inactive = true
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRenderKey(`${Math.random()}`)
    }, 50000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    async function loadData() {
      if (account) {
        // Load User staking balance
        try {
          const userData = await farmContract?.callStatic.balanceOf(account)
          setStakingBalance(userData || '0')
        } catch (e) {
          console.error('failed at setStakingBalance', e)
        }

        // Load User RewardToken Earned
        try {
          const userRewardTokenEarned =
            await farmContract?.callStatic.calcHarvestTot(account)
          setRewardTokenEarned(userRewardTokenEarned || '0')
        } catch (e) {
          console.error('failed at setRewardTokenEarned', e)
          setRewardTokenEarned('-1')
        }

        try {
          const lpTokenBalanceData =
            await lpTokenContract?.callStatic.balanceOf(account)
          setLpTokenBalance(lpTokenBalanceData)
        } catch (e) {
          console.error('failed at setLpTokenBalance', e)
        }

        // check for approval
        try {
          const farmAllowance = await lpTokenContract?.callStatic.allowance(
            account,
            farmAddress
          )
          setFarmEnabled(farmAllowance > 0)
        } catch (e) {
          console.error('failed at setLpTokenBalance', e)
        }
      }

      // check for total staked in farm.
      let stakedInFarm
      try {
        stakedInFarm = await lpTokenContract?.callStatic.balanceOf(farmAddress)
      } catch (e) {
        console.error('e is', e)
      }
      // Calculate APR
      // APR is year return / total staked.
      // yearReturn = rewardTokenPerShare * sharesLocked * blocksPerMonth * monthsPerYear * pricePerRewardToken

      // GET USD released per year per lp token
      const rewardTokenPerBlock = (await farmContract?.callStatic.pool())
        .perBlockNum

      const usdPerYear =
        Number(formatFixed(rewardTokenPerBlock, reward.decimals)) *
        (28800 * 365) *
        Number(rewardTokenPrice)

      // GET value of one LP token.
      // get bnbBalanceOf lp token contract

      const bnbBalanceOfLP = await bnbContract?.callStatic.balanceOf(lpAddress)
      const lpTokenTotalSupply = await lpTokenContract?.callStatic.totalSupply()

      // get value of one LP = bnbBalance * bnbPrice / totalSupply
      // scenarios:
      // LP = GHE
      // LP = BNB PAIR
      // LP = GHC
      let lpValue
      if (token2) {
        lpValue = bnbBalanceOfLP
          .mul(BigNumber.from(Number(bnbPrice).toFixed(0)))
          .div(lpTokenTotalSupply)
      } else if (token1 === 'GHC') {
        lpValue = ghcPrice
      } else if (token1 === 'GHE') {
        lpValue = ghePrice
      }
      const totalValueLocked =
        formatFixed(stakedInFarm, decimals) * Number(lpValue)

      // APR is wrong.
      // APR is value recieved per year / value locked
      // usdPerYearPerShare / lpValue
      const aprr = Number(usdPerYear) / Number(totalValueLocked)
      setApr(getBalanceString(getDecimalAmount(aprr).multipliedBy(100)))

      setTotalStaked(getBalanceString(getDecimalAmount(totalValueLocked)))
    }

    loadData()
  }, [
    account,
    renderKey,
    chainId,
    farmData.addressByChain,
    rewardTokenPrice,
    token1,
    bnbPrice,
    token2,
    decimals,
    reward.address,
    bnbContract?.callStatic,
    farmAddress,
    farmContract,
    lpAddress,
    lpTokenContract,
    reward.decimals,
    ghcPrice,
    ghePrice,
  ])

  const onDismiss = async () => {
    setShowModal(false)
  }

  const HandleEnable = async () => {
    const maxUint256 = ethers.constants.MaxUint256
    try {
      const spender = farmContract.address
      setFarmEnabledPending(true)
      const isEnabled = await lpTokenContract.approve(spender, maxUint256)
      await isEnabled.wait(1)
      toastSuccess('Contract Enabled', 'You can now stake in the pool')
    } catch (e) {
      if (!account) toastError('Error', 'Please connect your wallet first!')
      else
        toastError(
          'Error',
          'Please try again. Confirm the transaction and make sure you are paying enough gas!'
        )
      console.error('Enable error', e)
    }
    setFarmEnabledPending(false)
    setRenderKey(Math.random())
  }

  const HandleDeposit = async (amount) => {
    const amountToDeposit = getDecimalAmount(amount, decimals)
    let message = `Staked! Your funds have been staked in the pool!`
    setHarvestLoading(true)
    try {
      if (amount === 0) {
        message = `Successfully harvested rewardTokens`
      }
      const operation = await farmContract.stakeTokens(
        amountToDeposit.toFixed(0).toString()
      )
      await operation.wait(1)
      toastSuccess(message)
      setRenderKey(Math.random())
      onDismiss()
      setHarvestLoading(false)
    } catch (e) {
      console.error(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
      setHarvestLoading(false)
    }
  }

  const HandleWithdraw = async (amount) => {
    const amountToWithdraw = getDecimalAmount(amount, decimals)
    let message = `Unstaked! Your LP Tokens are back in your wallet!`
    try {
      if (amount === 0) {
        message = `Successfully unstaked your tokens`
      }
      // fail here.
      const tx = await farmContract.unstakeTokens(
        amountToWithdraw.toFixed(0).toString(),
        true
      )
      await tx.wait(1)
      toastSuccess(message)
      setRenderKey(Math.random())
      // fail here
      onDismiss()
    } catch (e) {
      console.error(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
    }
    setHarvestLoading(false)
  }

  const HandleEmergencyWithdraw = async (amount) => {
    const amountToWithdraw = getDecimalAmount(amount, decimals)
    let message = `Unstaked! Your LP Tokens are back in your wallet!`
    try {
      if (amount === 0) {
        message = `Successfully unstaked your tokens`
      }
      // fail here.
      const tx = await farmContract.emergencyUnstake()
      await tx.wait(1)
      toastSuccess(message)
      setRenderKey(Math.random())
      // fail here
      onDismiss()
    } catch (e) {
      console.error(e)
      toastError('Canceled', 'Please try again and confirm the transaction.')
    }
    setHarvestLoading(false)
  }

  const tokenLabel = tokenOverride || token2 ? `${token1}-${token2}` : token1
  const [onDeposit] = useModal(
    <DepositWithdrawModal
      max={new BigNumberJS(formatFixed(lpTokenBalance, 0)).multipliedBy(99).dividedBy(100)}
      title={`Stake ${tokenLabel}`}
      onDismiss={onDismiss}
      onConfirm={HandleDeposit}
      tokenName={tokenLabel}
      addLiquidityUrl={
        !token2
          ? '/#/swap'
          : '/#/add/BNB/0x22dE16487955AE1f842fa0C0bFf6083a7fDEfeDd'
      }
      decimals={decimals}
    />
  )

  const [onWithdraw] = useModal(
    <DepositWithdrawModal
      max={new BigNumberJS(formatFixed(stakingBalance, 0))}
      title={`Unstake ${tokenLabel}`}
      onDismiss={onDismiss}
      onConfirm={HandleWithdraw}
      onEmergencyWithdraw={HandleEmergencyWithdraw}
      tokenName={tokenLabel}
      addLiquidityUrl={
        !token2
          ? '/#/swap'
          : '/#/add/BNB/0x22dE16487955AE1f842fa0C0bFf6083a7fDEfeDd'
      }
      decimals={decimals}
    />
  )
  let rewardTokenStatus

  if (rewardTokenEarned === '0') {
    rewardTokenStatus = 'loading'
  } else if (typeof rewardTokenEarned === 'object') {
    rewardTokenStatus = 'loaded'
  } else if (rewardTokenEarned === '-1') {
    rewardTokenStatus = 'error'
  }
  return (
    <FormContainer inactive={!showMore}>
      <FormInfo inactive={inactive}>
        <LogoContainer>
          {tokenOverride ? (
            <FirstLogo
              src={`${
                process.env.REACT_APP_PUBLIC_URL
              }/images/logo-${tokenOverride.toLowerCase()}.png`}
            />
          ) : (
            <span>
              <FirstLogo
                src={`${
                  process.env.REACT_APP_PUBLIC_URL
                }/images/coins/${token1.toLowerCase()}.png`}
              />
              {token2 && (
                <SecondLogo
                  src={`${
                    process.env.REACT_APP_PUBLIC_URL
                  }/images/coins/${token2.toLowerCase()}.png`}
                />
              )}
            </span>
          )}
        </LogoContainer>
        <StackDataContainer>
          {tokenOverride ? (
            <BigLabel>Stake {tokenOverride}</BigLabel>
          ) : (
            <BigLabel>{token2 ? `${token1}-${token2}` : `${token1}`}</BigLabel>
          )}
          <ColoredValue>Earn {reward.name}</ColoredValue>
        </StackDataContainer>
        <StackDataContainer>
          <Label inactive={inactive}>{reward.name} Earned</Label>
          <ColoredValue inactive={inactive}>
            {rewardTokenStatus === 'loading' && 'Loading...'}
            {rewardTokenStatus === 'loaded' &&
              Number(formatFixed(rewardTokenEarned, reward.decimals)).toFixed(
                0
              )}
            {rewardTokenStatus === 'error' && (
              <QuestionHelper text="Error loading - try refreshing or if the problem persists, let us know in our telegram channel." />
            )}
          </ColoredValue>
        </StackDataContainer>
        <StackDataContainer>
          <Label>APR</Label>
          <Value inactive={inactive}>{inactive ? 'EXPIRED' : `${apr}%`}</Value>
        </StackDataContainer>
        <StackDataContainer>
          <Label>Total Staked</Label>
          <Value>{`${totalStaked} USD`}</Value>
        </StackDataContainer>
        <SmallStackDataContainer onClick={() => setShowMore(!showMore)}>
          <Details>Details</Details>

          {showMore ? <BlackChevronUp /> : <BlackChevronDown />}
        </SmallStackDataContainer>
      </FormInfo>
      {showMore && !showModal && (
        <DetailsContainer>
          <FarmsDetails>
            <FarmDetailsData>
              <FarmDetailsRow inactive={inactive}>
                {reward.name} Earned:
              </FarmDetailsRow>
              <FarmDetailsRow inactive={inactive} colored>
                {rewardTokenStatus === 'error' && (
                  <QuestionHelper text="Error loading - try refreshing or if the problem persists, let us know in our telegram channel." />
                )}
                {rewardTokenStatus === 'loaded' &&
                  Number(
                    formatFixed(rewardTokenEarned, reward.decimals)
                  ).toFixed(0)}
              </FarmDetailsRow>
              {!inactive && (
                <FarmDetailsRow inactive={inactive} small>
                  {rewardTokenStatus === 'loaded' &&
                    `${Number(
                      Number(formatFixed(rewardTokenEarned, reward.decimals)) *
                        rewardTokenPrice
                    ).toFixed(2)}~ USD`}
                </FarmDetailsRow>
              )}
            </FarmDetailsData>
            <FarmDetailsActions>
              {inactive ? (
                <MouseoverTooltip text="This pool is expired. Your pending rewards will be auto-harvested when you unstake.">
                  <FormActionButton inactive>Harvest</FormActionButton>
                </MouseoverTooltip>
              ) : (
                <FormActionButton
                  inactive={harvestLoading}
                  onClick={() => {
                    HandleDeposit(0)
                  }}
                >
                  {harvestLoading ? 'Pending...' : 'Harvest'}
                </FormActionButton>
              )}
            </FarmDetailsActions>
          </FarmsDetails>
          <FarmsDetails>
            <FarmDetailsData>
              <FarmDetailsRow inactive={inactive} small>
                {tokenOverride || token2 ? `${token1}-${token2}` : token1}{' '}
                Staked
              </FarmDetailsRow>
              <FarmDetailsRow inactive={inactive}>
                {Number(formatFixed(stakingBalance, decimals)).toFixed(2)}
              </FarmDetailsRow>
            </FarmDetailsData>
            {!farmEnabled ? (
              <FarmDetailsActions>
                <FormActionButton
                  inactive={farmEnabledPending}
                  onClick={HandleEnable}
                >
                  {farmEnabledPending ? 'Pending' : 'Enable'}
                </FormActionButton>
              </FarmDetailsActions>
            ) : (
              <FarmDetailsActions>
                {inactive ? (
                  <MouseoverTooltip text="This pool is expired. You cannot stake more. Pending rewards will be auto-harvested when you unstake.">
                    <FormActionButton small inactive onClick={onDeposit}>
                      +
                    </FormActionButton>
                  </MouseoverTooltip>
                ) : (
                  <FormActionButton small onClick={onDeposit}>
                    +
                  </FormActionButton>
                )}

                <FormActionButton small onClick={onWithdraw}>
                  -
                </FormActionButton>
              </FarmDetailsActions>
            )}
          </FarmsDetails>
        </DetailsContainer>
      )}
    </FormContainer>
  )
}

export default Farm
