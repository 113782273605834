import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'

export const FarmsContainer = styled.div`
  overflow-y: hidden;
  text-align: center;
  width: 100%;
`

export const StakeContainer = styled.div`
  min-width: 600px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

interface StakeHeadingProps {
  topMargin?: boolean
}

export const StakeHeading = styled.div<StakeHeadingProps>`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.topMargin ? '30px' : '0')};
  color: black;
  filter: drop-shadow(3px 1px 1px white);
`

interface InactiveProps {
  inactive?: boolean
  removeMargin?: boolean
}

export const FormContainer = styled.div<InactiveProps>`
  display: inline-block;
  width: 800px;
  margin: 10px 20px;
  border-radius: 30px;
  padding-left: 1px;
  border: ${(props) => (props.inactive ? '0' : '1px')} solid darkgrey;
  background-color: ${(props) => (props.inactive ? 'none' : 'rgba(1,1,1,0.4)')};
`

export const FormInfo = styled.div<InactiveProps>`
  padding: 10px 10px;
  border-radius: 30px;
  border: 1px solid
    ${(props) => (props.inactive ? 'darkgrey' : 'rgb(29, 162, 231)')};
  display: flex;
  position: relative;
  bottom: 1px;
  left: -1px;
  min-width: 700px;
  background-color: rgba(1, 1, 1, 0.6);
  box-shadow: ${(props) =>
    props.inactive
      ? ''
      : 'rgb(0 146 224) 0px 0px 1.5rem, rgb(0 146 224 / 70%) 0px 0px 1rem 0px inset;'};
`

export const DetailsContainer = styled.div`
  align-items: center;
  display: inline-flex;
`

export const FarmsDetails = styled.div`
  padding: 5px 15px;
  margin: 10px;
  display: inline-flex;
  background-color: rgba(1, 1, 1, 0.4);
  border-radius: 8px;
  width: 250px;
  height: 60px;
`

export const FarmDetailsData = styled.div`
  width: 60%;
  text-align: left;
`

export const FarmDetailsActions = styled.div`
  width: 40%;
  text-align: right;
  padding: 8px 0;
`

interface DataProps {
  small?: boolean
  colored?: boolean
  inactive?: boolean
}

export const FarmDetailsRow = styled.div<DataProps>`
  padding: 2px 0;
  font-size: ${(props) => (props.small ? '10px' : '14px')};
  color: ${(props) =>
    props.inactive
      ? 'darkgrey'
      : props.colored
      ? 'rgb(29, 162, 231)'
      : 'white'};
  font-weight: ${(props) => (props.colored ? '500' : 'normal')};
`

export const FormActionButton = styled.button<DataProps>`
  background-color: ${(props) =>
    props.inactive ? 'darkgrey' : 'rgb(29, 162, 231)'};
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 2px ${(props) => (props.small ? '10px' : '20px')};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.6;
    background-color: darkgrey;
  }
`

export const StakeData = styled.div<InactiveProps>`
  padding: 10px 10px;
  margin: 5px 0;
  border-radius: 30px;
  border: 1px solid
    ${(props) => (props.inactive ? 'darkgrey' : 'rgb(29, 162, 231)')};
  display: inline-block;
  min-width: 700px;
  background-color: white;
`

export const StackDataContainer = styled.div`
  width: 19%;
  float: left;
  display: content;
  text-align: left;
  padding: 11px 0;
`

export const SmallStackDataContainer = styled(StackDataContainer)`
  width: 11%;
  padding: 19px 0;
`

export const MediumStackDataContainer = styled(StackDataContainer)`
  width: 15%;
`

export const Label = styled.div<InactiveProps>`
  font-size: 14px;
  color: ${(props) => (props.inactive ? 'darkgrey' : 'white')};
`

export const BigLabel = styled(Label)`
  font-size: 16px;
  font-weight: 600;
`

export const Value = styled.div<InactiveProps>`
  font-size: 18px;
  padding: 2px 0;
  font-weight: 500;
  color: ${(props) => (props.inactive ? 'darkgrey' : 'white')};
`

export const ColoredValue = styled(Value)`
  color: ${(props) => (props.inactive ? 'darkgrey' : 'rgb(29, 162, 231)')};
`

export const Logo = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 0 12px;
  float: left;
  display: content;
`

export const BlackChevronDown = styled(ChevronDown)`
  width: 18px;
  height: 18px;
  color: darkgrey;
  fill: darkgrey !important;
  stroke: darkgrey;
  margin-left: 5px;
`

export const BlackChevronUp = styled(ChevronUp)`
  width: 18px;
  height: 18px;
  color: darkgrey;
  fill: darkgrey !important;
  stroke: darkgrey;
  margin-left: 5px;
`

export const Details = styled.div`
  font-size: 18px;
  color: darkgrey;
  display: inline-block;
  float: left;
  font-size: 14px;
  margin-top: 1px;
`

export const LiquidityPoolsContainer = styled(StackDataContainer)`
  margin-top: 50px;
`
export const LogoContainer = styled(StackDataContainer)`
  width: 14%;
  height: 60px;
  display: flex;
  justify-content: center;
`
export const FirstLogo = styled(Logo)`
  width: 40px;
  height: 40px;
  border: 1px solid white;
`

export const SecondLogo = styled(Logo)`
  width: 40px;
  height: 40px;
  position: relative;
  bottom: 40px;
  float: right;
  border: 1px solid white;
`
