import React from 'react'
import styled from 'styled-components'
import { Text, InputProps as PancakeInputProps } from '@pancakeswap-libs/uikit'

interface InputProps {
  title?: string
}

const StyledInputLayout = styled.div<PancakeInputProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 16px 8px 16px;
  width: 100%;
  align-items: start;
  & input,
  textarea {
    background: #61547a;
  }
  margin: 16px 0 16px 0;
`

const InputLayout: React.FC<InputProps> = ({ title, children }) => {
  return (
    <StyledInputLayout>
      <Text fontSize="14px">{title}</Text>
      {children}
    </StyledInputLayout>
  )
}

export default InputLayout
