import React, { useEffect } from 'react'
import styled from 'styled-components'
import NftCardV2 from 'components/NftCard/NftCardV2'

const ITEMS_PER_PAGE = 15

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: center;
`

export const Heading = styled.div`
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  margin-bottom: 10px;
  color: black;
  filter: drop-shadow(3px 1px 1px white);
`

export const SubHeading = styled(Heading)`
  font-size: 25px;
  margin: 10px;
`

const NftCardGrid = ({ nftList }) => {
  return (
    <FlexContainer>
      {nftList.length ? (
        <>
          {nftList.map((nft) => {
            const metadata = JSON.parse(nft?.metadata)
            return (
              <NftCardV2
                id={Number(nft?.token_id)}
                key={nft?.token_address + nft?.token_id}
                title={metadata?.name}
                description={metadata?.description}
                contract={nft?.token_address}
                mediaUri={metadata?.image}
                mediaType={
                  metadata?.image?.indexOf('mp4') > 0 ? 'video' : 'image'
                }
                attributes={metadata?.attributes}
                tokenUri={nft?.token_uri}
                collectionName={nft?.name}
                collectionSymbol={nft?.symbol}
                contractType={nft?.contract_type}
              />
            )
          })}
        </>
      ) : (
        <SubHeading>
          <div>No Items Found</div>
        </SubHeading>
      )}
    </FlexContainer>
  )
}

export default NftCardGrid
